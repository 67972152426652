const SendNotificationModalStyles = (theme) => ({
  root: {
    width: "100%",
    padding: "10px 10px",
  },
  textArea: {
    width: "-webkit-fill-available",
    resize: "none",
    borderRadius: "5px",
    border: "1.5px solid rgba(32, 30, 30, 0.12)",
    marginTop: "10px",
    "&:focus": {
      borderColor: " #ffcf6e !important",
      outline: "none",
    },
    padding: "6px",
  },
  dialogAction: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  },
  hunderPercentWidth: { width: "100%" },
});

export default SendNotificationModalStyles;
