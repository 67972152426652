const AboutUsStyles = (theme) => ({
  cardBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "200px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  containerPadding: {
    padding: "15px ",
  },
  button: {
    marginTop: "80px",
  },
  cardStyle: {
    height: "285px",
  },
  textStyle: {
    width: "540px",
    height: "100px",
    marginTop: "10px",
    maxWidth: "100%",
  },
  footerStyle: {
    marginRight: "230px",
    height: "110px",
  },
  buttonStyle: {
    width: "fit-content",
  },
  error: {
    color: "red",
  },
});

export default AboutUsStyles;
