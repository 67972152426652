import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import BannerTable from "components/Table/BannerTable";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import moment from "moment";
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function TableList({
  banners,
  setBanners,
  approveBanner,
  loading,
}) {
  let arr = [];
  arr =
    banners.length &&
    banners.map((req) => {
      return [
        req.title,
        req.description,
        req.status,
        moment(req.createdAt).fromNow(),
      ];
    });
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>لافتات</h4>
            <h4 className={classes.cardTitleWhite}>Banners</h4>
          </CardHeader>
          <CardBody>
            <BannerTable
              tableHeaderColor="info"
              tableHead={[
                "عنوان", //title
                "وصف", //description
                "الحالة", //status
                "أنشئت في", //created at
              ]}
              tableData={banners}
              approveBanner={approveBanner}
              loading={loading}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
