import React, { useState, useEffect } from "react";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardFooter from "components/Card/CardFooter.js";
import GridItem from "components/Grid/GridItem.js";
import { TextField, Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import EditBannerStyles from "./EditBannerStyles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "components/Card/Card.js";
import { editBanner } from "apis/auth";
import { getBanner } from "apis/auth";
import { useParams } from "react-router-dom";
import { fetchUrlFromMediaId } from "apis/doctors";
import { useHistory } from "react-router";
import FieldErrors from "components/FieldErrors";

const useStyles = makeStyles(EditBannerStyles);
function EditBanner() {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [banners, setBanners] = useState({});
  const [bannerFile, setBannerFile] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const notify = (message) => toast(message);
  const { id } = useParams();
  async function fetchData() {
    try {
      setLoading(true);
      let res = await getBanner(id);
      res.url = await fetchUrlFromMediaId(res.mediaId);
      setBanners(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  const handleEditBanner = async () => {
    try {
      const title = banners.title;
      const description = banners.title;
      let bannerId = parseInt(id);
      let res = await editBanner(bannerId, {
        title,
        description,
      });
      notify("تم تحديث البانر بنجاح");
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  function back() {
    history.push("/rtl/banners");
  }
  return (
    <div>
      <p className={classes.backStyle} onClick={back}>
        {" "}
        عودة
      </p>
      <GridContainer>
        <GridItem xs={4} sm={4} md={6}>
          <Card>
            <form onSubmit={handleSubmit(handleEditBanner)}>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>تحديث لافتة</h4>
              </CardHeader>
              <CardBody className={classes.cardBody}>
                <GridContainer>
                  <Box className={classes.box}>
                    <label className={classes.label}>
                      <label className={classes.labelTwo}>
                        {bannerFile?.name}
                      </label>
                    </label>
                  </Box>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <div>
                      <img src={banners?.url?.url} className={classes.image} />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    <div className={classes.div}>
                      <label>عنوان</label>
                      <TextField
                        value={banners?.title}
                        name="title"
                        onChange={(e) => {
                          setBanners({
                            ...banners,
                            title: e.target.value,
                          });
                        }}
                        inputRef={register({
                          required: "title required",
                        })}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors error={errors?.title?.message} />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                    <div className={classes.div}>
                      <label>وصف</label>
                      <TextField
                        value={banners?.description}
                        name="description"
                        onChange={(e) => {
                          setBanners({
                            ...banners,
                            description: e.target.value,
                          });
                        }}
                        inputRef={register({
                          required: "description required",
                        })}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button type="submit" color="info">
                  تحديث
                </Button>
                <ToastContainer />
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default EditBanner;
