const SignInStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    marginBottom: "20px",
  },
  footerStyle: {
    marginRight: "230px",
  },
  backStyle: {
    marginRight: "30px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  cardBodyStyles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "50px",
  },
  boxLabelStyles: {
    fontSize: "12px",
    cursor: "pointer",
    color: "#006FFF",
    marginLeft: "10px",
  },
  boxStyles: {
    marginLeft: "20px",
    cursor: "pointer",
  },
  labelStyles: {
    marginLeft: "10px",
    color: "#201E1E",
  },
});
export default SignInStyles;
