const EditBannerStyles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  div: {
    display: "flex",
    flexDirection: "column",
  },
  gridContainer: {
    marginTop: "5px",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "180px",
  },
  label: {
    fontSize: "12px",
    cursor: "pointer",
    color: "#006FFF",
    marginLeft: "10px",
  },
  box: {
    marginLeft: "20px",
    cursor: "pointer",
  },
  labelTwo: {
    marginLeft: "10px",
    color: "#201E1E",
  },
  iconStyle: {
    marginRight: "8px",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "580px",
  },
  image: {
    marginRight: "100px",
    height: "350px",
    width: "350px",
  },
  backStyle: {
    marginRight: "30px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  error: {
    marginBottom: "20px",
  },
});

export default EditBannerStyles;
