import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ReviewTable from "components/Table/ReviewTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Switch from "@material-ui/core/Switch";
import moment from "moment";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList({ reviews, setReviews, loading }) {
  let arr = [];
  arr =
    reviews.length &&
    reviews.map((req) => {
      return [
        req.doctorName,
        req.patientName,
        req.text,
        req.rating,
        moment(req.createdAt).fromNow(),
      ];
    });
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>المراجعات</h4>
            <h4 className={classes.cardTitleWhite}>Reviews</h4>
          </CardHeader>
          <CardBody>
            <ReviewTable
              tableHeaderColor="info"
              tableHead={[
                "اسم الطبيب", //doctor name
                "اسم المريض", //patient name
                "إعادة النظر", // review
                "تقييم", //rating
                "أنشئت في", //created at
              ]}
              tableData={reviews}
              loading={loading}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
