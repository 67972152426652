/*eslint-disable*/
import React from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// core components
import { useHistory } from "react-router";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";
import { fetchStats } from "../../apis/stats";
import "./RTLPageStyles.css";

const useStyles = makeStyles(styles);

export default function RTLPage() {
  const [loading, setLoading] = React.useState(false);
  const [stats, setStats] = React.useState({});

  const classes = useStyles();
  const history = useHistory();

  async function fetchData() {
    try {
      setLoading(true);
      let res = await fetchStats();
      setStats(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem
          xs={12}
          sm={6}
          md={3}
          onClick={() => {
            history.push(`/rtl/doctors`);
          }}
          className={classes.sixtyHeight}
        >
          <Card className={classes.twoHunderdHeight}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning" className="iconStyles">
                <small className="textStyles">{stats.doctorsCount}</small>
              </CardIcon>
              <h3 className={classes.cardTitle}>المختصون</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem
          xs={12}
          sm={6}
          md={3}
          onClick={() => {
            history.push(`/rtl/patients`);
          }}
          className={classes.sixtyHeight}
        >
          <Card className={classes.twoHunderdHeight}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning" className="iconStyles">
                <small className="textStyles">{stats.patientsCount}</small>
              </CardIcon>
              <h3 className={classes.cardTitle}>العملاء</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem
          xs={12}
          sm={6}
          md={3}
          onClick={() => {
            history.push(`/rtl/appointments`);
          }}
          className={classes.sixtyHeight}
        >
          <Card className={classes.twoHunderdHeight}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning" className="iconStyles">
                <small className="textStyles"> {stats.appointmentsCount}</small>
              </CardIcon>
              <h3 className={classes.cardTitle}>تعيينات</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem
          xs={12}
          sm={6}
          md={3}
          onClick={() => {
            history.push(`/rtl/transactions`);
          }}
          className={classes.sixtyHeight}
        >
          <Card className={classes.twoHunderdHeight}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning" className="iconStyles">
                <small className="textStyles">{stats.transactionsCount}</small>
              </CardIcon>
              <h3 className={classes.cardTitle}>المعاملات</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem
          xs={12}
          sm={6}
          md={3}
          onClick={() => {
            history.push(`/rtl/withdraw-requests`);
          }}
          className={classes.sixtyHeight}
        >
          <Card className={classes.twoHunderdHeight}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning" className="iconStyles">
                <small className="textStyles">
                  {stats.withdrawalRequestCount}
                </small>
              </CardIcon>
              <h3 className={classes.cardTitle}>طلبات الانسحاب</h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem
          xs={12}
          sm={6}
          md={3}
          onClick={() => {
            history.push(`/rtl/lectures`);
          }}
          className={classes.sixtyHeight}
        >
          <Card className={classes.twoHunderdHeight}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning" className="iconStyles">
                <small className="textStyles">{stats.lecturesCount}</small>
              </CardIcon>
              <h3 className={classes.cardTitle}>محاضرات</h3>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
