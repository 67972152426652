import React, { useState, useEffect } from "react";
import AppointmentsList from "./AppointmentsList";
import {
  fetchAppointments,
  editAppointment,
  blockUser,
} from "../../../apis/doctors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Appointments() {
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const notify = (message) => toast(message);

  async function fetchData() {
    try {
      setLoading(true);
      let requests = await fetchAppointments();
      setRequests([...requests]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify("error loading appointments");
    }
  }

  const approveAppointment = async (appointment) => {
    try {
      await editAppointment(appointment.id, {
        isApproved: !appointment.isApproved,
      });
      if (appointment.isApproved === true) {
        notify("لم تتم الموافقة على الموعد بنجاح");
      } else {
        notify("تمت الموافقة على الموعد بنجاح");
      }
      fetchData();
    } catch (error) {
      notify("خطأ أثناء الموافقة على الموعد!");
    }
  };
  const bookAppointment = async (appointment) => {
    try {
      await editAppointment(appointment.id, {
        isBooked: !appointment.isBooked,
      });
      if (appointment.isBooked === true) {
        notify("تم إلغاء الموعد بنجاح");
      } else {
        notify("تم حجز الموعد بنجاح");
      }
      fetchData();
    } catch (error) {
      notify("خطأ أثناء الموافقة على الموعد!");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <AppointmentsList
        requests={requests}
        setRequests={setRequests}
        approveAppointment={approveAppointment}
        bookAppointment={bookAppointment}
        loading={loading}
      />
      <ToastContainer />
    </div>
  );
}

export default Appointments;
