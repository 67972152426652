import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForm } from "react-hook-form";
import { getQueryById } from "apis/admins";
import { useParams } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { compactObj } from "../../utilities";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router";
import styles from "./QueryDetailStyles";

const useStyles = makeStyles(styles);

export default function QueryDetails() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState([]);
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  async function fetchData() {
    try {
      setLoading(true);
      const query = await getQueryById(parseInt(id));
      setQuery(query);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  function back() {
    history.push("/rtl/queries");
  }
  function updateQuery(e) {
    e.preventDefault();
  }
  return (
    <div>
      <p className={classes.backStyle} onClick={back}>
        {" "}
        عودة
      </p>
      <form onSubmit={updateQuery}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>تفاصيل الاستعلام</h4>
              </CardHeader>
              <CardBody className={classes.cardBody}>
                {" "}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>اسم المستخدم</label>
                      <TextField
                        name="name"
                        disabled
                        value={query?.User?.fullName}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>البريد الإلكتروني للمستخدم</label>
                      <TextField
                        name="name"
                        disabled
                        value={query?.User?.email}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>البريد الإلكتروني للمستخدم</label>
                      <TextField
                        name="name"
                        disabled
                        value={query?.User?.email}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>الحالة</label>
                      <TextField
                        name="name"
                        value={query?.status}
                        disabled
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>وصف</label>
                      <TextField
                        disabled
                        name="name"
                        value={query?.description}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>أنشئت في</label>
                      <TextField
                        disabled
                        name="name"
                        value={moment(query.createdAt).fromNow()}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button type="submit" color="info">
                  تحديث
                </Button>
              </CardFooter>
              <ToastContainer />
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
