import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import DoctorTable from "components/Table/DoctorTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Switch from "@material-ui/core/Switch";
import moment from "moment";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList({
  doctors,
  blockDoctor,
  approveDoctor,
  updateIsFeatured,
}) {
  let arr = doctors.map((doctor) => {
    return [
      doctor.fullName,
      doctor.email,
      doctor.gender,
      doctor.city,
      <Switch
        checked={doctor.isBlocked}
        onChange={() => {
          blockDoctor(doctor);
        }}
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />,
      <Switch
        checked={doctor.isApproved}
        onChange={() => {
          approveDoctor(doctor);
        }}
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />,
      moment(doctor.createdAt).fromNow(),
    ];
  });
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>المختصون</h4>
            <h4 className={classes.cardTitleWhite}>Doctors</h4>
          </CardHeader>
          <CardBody>
            <DoctorTable
              tableHeaderColor="info"
              tableHead={[
                "اسم", ///name
                "البريد الإلكتروني", //email
                "جنس", //gender
                "مدينة", //city
                // "رفض", //block
                "يوافق", //approve
                "أنشئت في", //created at
                // "أجراءات", //actions
                "هي واردة", // isFeatured
              ]}
              tableData={doctors}
              approveDoctor={approveDoctor}
              blockDoctor={blockDoctor}
              updateIsFeatured={updateIsFeatured}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
