import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForm } from "react-hook-form";
import { getLectureById, updateLecture } from "apis/lectures";
import { TextField, Box } from "@material-ui/core";
import { compactObj } from "../../utilities";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AttatchFileIcon from "../../components/AttatchFileIcon";
import { useParams } from "react-router-dom";
import FieldErrors from "components/FieldErrors";
import { fetchUrlFromMediaId } from "apis/doctors";
import LectureStyles from "./LectureStyles";
import { useHistory } from "react-router";
const useStyles = makeStyles(LectureStyles);

export default function LectureDetails() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [lecture, setLecture] = useState({});
  const { register, handleSubmit, errors } = useForm();
  const [file, setFile] = useState("");
  const { id } = useParams();
  const history = useHistory();
  const [buttonClick, setButtonClick] = useState(false);

  let payload = [];
  const iconStyling = {
    marginRight: "8px",
  };

  async function fetchData() {
    try {
      setLoading(true);
      let lecture = await getLectureById(parseInt(id));
      if (lecture.mediaId) {
        lecture.url = await fetchUrlFromMediaId(lecture.mediaId);
      }
      setLecture(lecture);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const notify = (message) => toast(message);
  const handleUpdate = async () => {
    setButtonClick(true);

    try {
      let data = compactObj(
        _.pick(lecture, ["title", "subject", "price", "link", "description"])
      );
      let res = await updateLecture(id, data);
      notify("تم تحديث تفاصيل المحاضرة بنجاح");
    } catch (e) {
      notify("الرجاء إدخال الحقول المحددة");
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  function back() {
    history.push("/rtl/lectures");
  }
  return (
    <div>
      <p className={classes.backStyle} onClick={back}>
        {" "}
        عودة
      </p>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form noValidate onSubmit={handleSubmit(handleUpdate)}>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>تحديث محاضرة</h4>
              </CardHeader>
              <CardBody className={classes.cardBody}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <div>
                      <img src={lecture?.url?.url} className={classes.image} />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>عنوان</label>
                      <TextField
                        value={lecture?.title}
                        name="title"
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            title: e.target.value,
                          });
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={buttonClick && !lecture.title && "العنوان مطلوب"}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>العنوان الفرعي</label>
                      <TextField
                        value={lecture?.subTitle}
                        name="subTitle"
                        variant="outlined"
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            subTitle: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick &&
                          !lecture.subTitle &&
                          "حقل مطلوب العنوان الفرعي"
                        }
                      />{" "}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>وصف</label>
                      <TextField
                        value={lecture?.description}
                        name="description"
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            description: e.target.value,
                          });
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick &&
                          !lecture.description &&
                          "الوصف حقل مطلوب"
                        }
                      />{" "}
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label> موضوع</label>
                      <TextField
                        name="subject"
                        value={lecture?.subject}
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            subject: e.target.value,
                          });
                        }}
                        variant="outlined"
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick && !lecture.subject && "الموضوع مطلوب حقل"
                        }
                      />{" "}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>السعر</label>
                      <TextField
                        name="price"
                        value={lecture?.price}
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            price: e.target.value,
                          });
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick && !lecture.price && "السعر مطلوب حقل"
                        }
                      />{" "}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label> حلقة الوصل</label>
                      <TextField
                        name="link"
                        value={lecture?.link}
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            link: e.target.value,
                          });
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick && !lecture.link && "حلقة الوصل مطلوب حقل"
                        }
                      />{" "}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>اسم الطبيب وتخصصه</label>
                      <TextField
                        name="doctorNameAndSpeciality"
                        value={lecture?.doctorNameAndSpeciality}
                        variant="outlined"
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            doctorNameAndSpeciality: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick &&
                          !lecture.doctorNameAndSpeciality &&
                          "مطلوب حقل اسم الطبيب والتخصص"
                        }
                      />
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>نوع</label>
                      <TextField
                        select
                        name="type"
                        value={lecture?.type}
                        SelectProps={{
                          native: true,
                        }}
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            type: e.target.value,
                          });
                        }}
                        variant="outlined"
                      >
                        <option key={"DirectLectures"} value={"DirectLectures"}>
                          {"Direct Lectures"}
                        </option>
                        <option
                          key={"RecordedLectures"}
                          value={"RecordedLectures"}
                        >
                          {"Recorded Lectures"}
                        </option>
                      </TextField>
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick && !lecture.type && "نوع الحقل المطلوب"
                        }
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <Box className={classes.boxStyles}>
                    <label className={classes.labelStyles}>
                      <AttatchFileIcon style={iconStyling} />
                      تحميل الصورة
                      <input
                        type="file"
                        className={classes.inputStyles}
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                      <label className={classes.label2Styles}>
                        {file?.name}
                      </label>
                    </label>
                  </Box>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button type="submit" color="info">
                  تحديث
                </Button>
              </CardFooter>
            </form>
            <ToastContainer />
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
