import React, { useState, useEffect } from "react";
import DoctorsList from "./DoctorsList";
import {
  fetchDoctors,
  blockUser,
  approveUser,
  editDoctorProfile,
} from "../../../apis/doctors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Doctors() {
  const [loading, setLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const notify = (message) => toast(message);

  const blockDoctor = async (user) => {
    try {
      let res = await blockUser(
        user.id,
        user.isBlocked === true ? false : true
      );
      if (user.isBlocked === true) {
        notify("تم إلغاء حظر الطبيب بنجاح");
      } else {
        notify("حظر الطبيب بنجاح");
      }
      fetchData();
    } catch (error) {
      user.isBlocked = !user.isBlocked;
      setDoctors([...doctors]);
      notify("خطأ أثناء حظر المستخدم!");
    }
  };

  const updateIsFeatured = async (user) => {
    try {
      let res = await editDoctorProfile(user.id, {
        isFeatured: user.UserDoctor.isFeatured === true ? "false" : "true",
      });
      if (user.UserDoctor.isFeatured === true) {
        notify("تم تعطيل الطبيب بنجاح");
      } else {
        notify("ظهر الطبيب بنجاح");
      }
      fetchData();
    } catch (error) {
      user.isFeatured = !user.isFeatured;
      setDoctors([...doctors]);
      notify("خطأ أثناء تحديث الطبيب!");
    }
  };

  const approveDoctor = async (user) => {
    try {
      await approveUser(user.id, user.isApproved === "1" ? "0" : "1");
      if (user.isApproved === "1") {
        notify("لم يوافق الطبيب بنجاح");
      } else {
        notify("وافق الطبيب بنجاح");
      }
      fetchData();
    } catch (error) {
      user.isApproved = user.isApproved === "1" ? "0" : "1";
      setDoctors([...doctors]);
      notify("خطأ أثناء الموافقة على المستخدم!");
    }
  };

  async function fetchData() {
    try {
      setLoading(true);
      let doctors = await fetchDoctors();
      setDoctors([...doctors]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify("خطأ أثناء تحميل المختصون!");
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <DoctorsList
        doctors={doctors}
        setDoctors={setDoctors}
        blockDoctor={blockDoctor}
        updateIsFeatured={updateIsFeatured}
        approveDoctor={approveDoctor}
      />
      <ToastContainer />
    </div>
  );
}

export default Doctors;
