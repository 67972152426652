import axios from "axios";

const axiosConfigured = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});
let token = window.localStorage.getItem("token");

export const setAuthHeader = (token) => {
  axiosConfigured.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

setAuthHeader(token);

const onSuccess = function (response) {
  console.debug("Request Successful!", response);
  return response.data;
};

const onError = function (error) {
  console.error("Request Failed:", error.config);
  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx
    console.error("Status:", error.response.status);
    console.error("Data:", error.response.data);
    console.error("Headers:", error.response.headers);
  } else {
    // Something else happened while setting up the request
    // triggered the error
    console.error("Error Message:", error.message);
  }

  return Promise.reject({
    errMsg: error?.response?.data?.message || error.message,
    status: error?.response?.status,
  });
};
const client = axiosConfigured;
client.interceptors.response.use(onSuccess, onError);
client.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
export default axiosConfigured;
export { client };
