import axios from "./axios-configured";

export const fetchModerators = async () => {
  return axios.get("/admin/moderators");
};

export const fetchFaqs = async () => {
  return axios.get("/admin/faqs");
};

export const changePassword = async (id, payload) => {
  return axios.patch(`/admin/moderator/${id}/change-password`, payload);
};

export const fetchQueries = async () => {
  return axios.get("/admin/queries");
};

export const getQueryById = async (id) => {
  return axios.get(`/admin/queries/${id}`);
};

export const markQueryAsCompleted = async (id) => {
  return axios.post(`/admin/queries/${id}`);
};

export const fetchSettings = async () => {
  return axios.get("/admin/settings");
};

export const upsertSettings = async (payload) => {
  return axios.post("/admin/settings", payload);
};
