import React, { useState, useEffect } from "react";
import LecturesList from "./LecturesList";
import { fetchLectures } from "../../../apis/lectures";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Doctors() {
  const [loading, setLoading] = useState(false);
  const [lectures, setLectures] = useState([]);
  const notify = (message) => toast(message);

  async function fetchData() {
    try {
      setLoading(true);
      let lectures = await fetchLectures();
      setLectures([...lectures]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify("خطأ أثناء تحميل المحاضرات!");
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <LecturesList lectures={lectures} setLectures={setLectures} />
      <ToastContainer />
    </div>
  );
}

export default Doctors;
