import React from "react";
import { Box, Typography } from "@material-ui/core";
import FieldErrorsStyles from "./FieldErrorStyles";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(FieldErrorsStyles);
function FieldErrors({ error }) {
  const classes = useStyles();

  console.log({ error });
  return (
    <Box className={classes.box}>
      <Typography
        className={classes.typography}
        variant="caption"
        color="secondary"
      >
        {error && error}
      </Typography>
    </Box>
  );
}

export default FieldErrors;
