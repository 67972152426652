import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TransTable from "components/Table/TransTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import styles from "./styles";
const useStyles = makeStyles(styles);

export default function TableList({ transactions, setTransactions, loading }) {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>المعاملات</h4>
            <h4 className={classes.cardTitleWhite}>Transactions</h4>
          </CardHeader>
          <CardBody>
            <TransTable
              tableHeaderColor="info"
              tableHead={[
                "اسم االمستخدم", //"User Name",
                "نوع", //"Type",
                "الضمان", //amount
                "دين", // actions
                " الإئتمان", //"status",
                "أنشئت في", //created at
              ]}
              tableData={transactions}
              loading={loading}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
