import React, { useState, useEffect } from "react";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardFooter from "components/Card/CardFooter.js";
import GridItem from "components/Grid/GridItem.js";
import { TextField, Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import AttatchFileIcon from "../../components/AttatchFileIcon";
import { createBanner } from "apis/auth";
import getExt from "./getExtension";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { getSignedUrl } from "apis/lectures";
import { compactObj } from "../../utilities";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const useStyles = makeStyles(styles);
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import { useForm } from "react-hook-form";

function BannerForm({ modalOpen, setModalOpen }) {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [banners, setBanners] = useState({});
  const [bannerFile, setBannerFile] = useState("");
  const notify = (message) => toast(message);

  const handleCreateBanner = async () => {
    try {
      if (bannerFile) {
        let signedApiRes = await getSignedUrl(getExt(bannerFile.name));
        var options = {
          headers: {
            "Content-Type": bannerFile.type,
          },
        };
        let awsRes = await axios.put(
          `${signedApiRes.uploadUrl}`,
          bannerFile,
          options
        );
        if (awsRes.status === 200) {
          banners.mediaId = signedApiRes.medium.id;
        }
      }
      let data = compactObj(banners);
      let res = await createBanner(data);
      notify("تمت إضافة البانر بنجاح");
      setModalOpen(!modalOpen);
      window.location.reload(false);
    } catch (e) {
      setModalOpen(!modalOpen);
      notify(e.errMsg);
      console.log(e);
    }
  };
  const iconStyling = {
    marginRight: "8px",
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Dialog
          open={modalOpen}
          onClose={() => {
            setModalOpen(!modalOpen);
          }}
          className={classes.root}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <form onSubmit={handleSubmit(handleCreateBanner)}>
            <CardBody className={classes.cardBody}>
              <GridContainer>
                <Box className={classes.box}>
                  <label className={classes.label}>
                    <AttatchFileIcon style={iconStyling} />
                    تحميل الصورة
                    <input
                      type="file"
                      className={classes.inputStyles}
                      onChange={(e) => {
                        setBannerFile(e.target.files[0]);
                      }}
                    />
                    <label className={classes.labelTwo}>
                      {bannerFile?.name}
                    </label>
                  </label>
                </Box>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <div className={classes.div}>
                    <label>عنوان</label>
                    <TextField
                      defaultValue={banners?.title}
                      name="title"
                      onChange={(e) => {
                        setBanners({
                          ...banners,
                          title: e.target.value,
                        });
                      }}
                      inputRef={register({
                        required: "title required",
                      })}
                      variant="outlined"
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <div className={classes.div}>
                    <label>وصف</label>
                    <TextField
                      defaultValue={banners?.description}
                      name="description"
                      onChange={(e) => {
                        setBanners({
                          ...banners,
                          description: e.target.value,
                        });
                      }}
                      inputRef={register({
                        required: "description required",
                      })}
                      variant="outlined"
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button
                type="submit"
                color="info"
                className={classes.buttonStyles}
              >
                تحديث
              </Button>
              <ToastContainer />
            </CardFooter>
          </form>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
}

export default BannerForm;
