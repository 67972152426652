import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForm } from "react-hook-form";
import { getTransactById } from "apis/doctors";
import { useParams } from "react-router-dom";
import { TextField } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  div: {
    display: "flex",
    flexDirection: "column",
  },
  backStyle: {
    marginRight: "30px",
    textDecoration: "underline",
    cursor: "pointer",
  },
};

const useStyles = makeStyles(styles);

export default function TransactionDetails() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const { id } = useParams();
  const history = useHistory();

  async function fetchData() {
    try {
      setLoading(true);
      let user = await getTransactById(id);
      setTransaction(user);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  function back() {
    history.push("/rtl/transactions");
  }
  return (
    <div>
      <p className={classes.backStyle} onClick={back}>
        {" "}
        عودة
      </p>

      <form>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>Transaction Details</h4>
              </CardHeader>
              <CardBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "200px",
                }}
              >
                {" "}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>اسم االمستخدم</label>
                      <TextField
                        name="UserName"
                        value={transaction.User?.fullName}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>نوع المستخدم</label>
                      <TextField
                        name="name"
                        value={transaction?.User?.type}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>الضمان</label>
                      <TextField
                        name="name"
                        value={transaction?.escrow}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label> دين</label>
                      <TextField
                        name="name"
                        value={transaction?.debit}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label> الإئتمان</label>
                      <TextField
                        name="name"
                        value={transaction?.credit}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter></CardFooter>
              <ToastContainer />
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
