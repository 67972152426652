import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import Button from "components/CustomButtons/Button.js";
import CreateModerator from "views/DetailPages/CreateModerator";
import ChangePassword from "views/DetailPages/ChangePassword";
import LockIcon from "@material-ui/icons/Lock";
import TableLoading from "components/TableLoading";
const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = React.useState(false);
  const [activeId, setActiveId] = React.useState("");
  const classes = useStyles();

  const { tableHead, tableData, tableHeaderColor, loading } = props;

  return (
    <div className={classes.tableResponsive}>
      <Button
        type="submit"
        color="info"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        اضف جديد
      </Button>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {loading ? (
            <TableLoading tableHead={tableHead} />
          ) : tableData.length > 0 ? (
            tableData.map((prop, key) => {
              return (
                <TableRow key={key} className={classes.tableBodyRow}>
                  <TableCell className={classes.tableCell}>
                    {prop?.email || "----"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {prop?.type || "----"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {moment(prop?.createdAt).fromNow()}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {moment(prop?.updatedAt).fromNow()}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <LockIcon
                      onClick={() => {
                        setPasswordModalOpen(true);
                        setActiveId(prop?.id);
                      }}
                    >
                      تغيير كلمة المرور
                    </LockIcon>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <div className={classes.center}>لا يوجد مشرف</div>
          )}
        </TableBody>
      </Table>
      <CreateModerator modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <ChangePassword
        passwordModalOpen={passwordModalOpen}
        setPasswordModalOpen={setPasswordModalOpen}
        activeId={activeId}
      />
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
