import React, { useState, useEffect } from "react";
import ReviewsList from "./ReviewsList";
import { fetchReviews } from "../../../apis/doctors";

function Reviews() {
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState([]);

  async function fetchData() {
    try {
      setLoading(true);
      let requests = await fetchReviews();
      setReviews([...requests]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ReviewsList
        loading={loading}
        reviews={reviews}
        setReviews={setReviews}
      />
    </div>
  );
}

export default Reviews;
