import React, { useState } from "react";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardFooter from "components/Card/CardFooter.js";
import GridItem from "components/Grid/GridItem.js";
import { TextField, Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { useForm } from "react-hook-form";
import AttatchFileIcon from "../../components/AttatchFileIcon";
import { createTermsAndCondition, createAboutUs } from "apis/auth";
import { getSignedUrl } from "apis/lectures";
import { compactObj } from "../../utilities";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import getExt from "./getExtension";

import Card from "components/Card/Card.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import AboutUsForm from "./AboutUsForm";
import { fetchTermsAndConditions } from "apis/doctors";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(styles);

function TermsAndConditionsForm() {
  const [termsAndCondition, setTermsAndCondition] = useState({});
  const [termsAndConditionFile, setTermsAndConditionFile] = useState("");
  const [aboutUs, setAboutUs] = useState({});
  const [aboutUsLoading, setAboutUsLoading] = useState(false);
  const [termsAndConitionsUrl, setTermsAndConditionsUrl] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const notify = (message) => toast(message);

  const handleCreateTermsAndCondition = async () => {
    try {
      let extension = getExt(termsAndConditionFile.name);

      if (termsAndConditionFile) {
        let signedApiRes = await getSignedUrl(extension);

        var options = {
          headers: {
            "Content-Type": termsAndConditionFile.type,
          },
        };
        let awsRes = await axios.put(
          `${signedApiRes.uploadUrl}`,
          termsAndConditionFile,
          options
        );

        if (awsRes.status === 200) {
          termsAndCondition.mediaId = signedApiRes.medium.id;
        }
      }
      let data = compactObj(termsAndCondition);
      let res = await createTermsAndCondition(data);
      notify("تمت إضافة الشروط والأحكام بنجاح");
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
    }
  };

  const handleCreateAboutUs = async () => {
    try {
      let data = compactObj(aboutUs);
      let res = await createAboutUs(data);
      notify("تم إضافة معلومات عنا بنجاح");
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
    }
  };
  const iconStyling = {
    marginRight: "8px",
  };

  React.useEffect(() => {
    async function getTermsAndConditions() {
      setImageLoading(true);
      try {
        let res = await fetchTermsAndConditions();
        setTermsAndConditionsUrl(res.url);
        setImageLoading(false);
      } catch (err) {
        setImageLoading(false);
      }
    }
    getTermsAndConditions();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={4} sm={4} md={6}>
        <Card>
          {aboutUsLoading ? null : (
            <AboutUsForm
              aboutUs={aboutUs}
              setAboutUs={setAboutUs}
              handleCreateAboutUs={handleCreateAboutUs}
            />
          )}
        </Card>
      </GridItem>
      <GridItem xs={4} sm={4} md={6}>
        <Card>
          <form
            noValidate
            onSubmit={handleSubmit(handleCreateTermsAndCondition)}
          >
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>الأحكام والشروط</h4>
            </CardHeader>
            <CardBody className={classes.cardBodyStyles}>
              <GridContainer>
                <Box className={classes.boxStyles}>
                  <label className={classes.boxLabelStyles}>
                    <AttatchFileIcon style={iconStyling} />
                    تحميل الصورة
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setTermsAndConditionFile(e.target.files[0]);
                      }}
                    />
                    <label className={classes.labelStyles}>
                      {termsAndConditionFile?.name}
                    </label>
                  </label>
                </Box>
              </GridContainer>
            </CardBody>
            <div className={classes.footer}>
              {imageLoading ? (
                <Skeleton variant="rect" height={200} />
              ) : (
                <img
                  src={termsAndConitionsUrl}
                  className={classes.termsAndConditionsImage}
                />
              )}
            </div>
            <CardFooter>
              <Button type="submit" color="info">
                تحديث
              </Button>
              <ToastContainer />
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default TermsAndConditionsForm;
