import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForm } from "react-hook-form";
import { createNewLecture, getSignedUrl } from "apis/lectures";
import { TextField, Box } from "@material-ui/core";
import { compactObj } from "../../utilities";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AttatchFileIcon from "../../components/AttatchFileIcon";
import getExt from "./getExtension";
import axios from "axios";
import _ from "lodash";
import FieldErrors from "components/FieldErrors";
import CreateLectureStyles from "./CreateLectureStyles";
import "../../assets/css/CreateLectureStyles.css";
import { useHistory } from "react-router";

const useStyles = makeStyles(CreateLectureStyles);

export default function CreateLecture() {
  const classes = useStyles();
  const [lecture, setLecture] = useState({});
  const { register, handleSubmit, errors } = useForm();
  const [file, setFile] = useState("");
  const history = useHistory();
  const [buttonClick, setButtonClick] = useState(false);

  const notify = (message) => toast(message);
  const iconStyling = {
    marginRight: "8px",
  };
  const handleCreate = async () => {
    setButtonClick(true);
    if (
      lecture?.title &&
      lecture?.subject &&
      lecture?.type &&
      lecture?.price &&
      lecture.doctorNameAndSpeciality &&
      lecture?.link &&
      lecture?.subTitle &&
      lecture?.description
    ) {
      try {
        if (file) {
          let signedApiRes = await getSignedUrl(getExt(file.name));
          var options = {
            headers: {
              "Content-Type": file.type,
            },
          };
          let awsRes = await axios.put(
            `${signedApiRes.uploadUrl}`,
            file,
            options
          );
          if (awsRes.status === 200) {
            lecture.mediaId = signedApiRes.medium.id;
          }
        }
        lecture.price = parseInt(lecture.price);
        lecture.audience = "audience";
        let data = compactObj(lecture);
        let res = await createNewLecture(data);
        notify("تم إنشاء الندوة بنجاح");
        history.push("/rtl/lectures");
      } catch (e) {
        notify(e.errMsg);
        console.log(e);
      }
    } else {
      notify("جميع الحقول مطلوبة");
    }
  };
  function back() {
    history.push("/rtl/lectures");
  }

  return (
    <div>
      <p className={classes.backStyle} onClick={back}>
        {" "}
        عودة
      </p>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form noValidate onSubmit={handleSubmit(handleCreate)}>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>إنشاء محاضرة</h4>
              </CardHeader>
              <CardBody className={classes.cardBody}>
                <GridContainer>
                  <Box className="change">
                    <label className={classes.label}>
                      <AttatchFileIcon style={iconStyling} />
                      تحميل الصورة
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                      <label className={classes.labelTwo}>{file?.name}</label>
                    </label>
                  </Box>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>عنوان</label>
                      <TextField
                        value={lecture?.title}
                        name="title"
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            title: e.target.value,
                          });
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={buttonClick && !lecture.title && "العنوان مطلوب"}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>العنوان الفرعي</label>
                      <TextField
                        value={lecture?.subTitle}
                        name="subTitle"
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            subTitle: e.target.value,
                          });
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick &&
                          !lecture.subTitle &&
                          "حقل مطلوب العنوان الفرعي"
                        }
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>وصف</label>
                      <TextField
                        value={lecture?.description}
                        name="description"
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            description: e.target.value,
                          });
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick &&
                          !lecture.description &&
                          "الوصف حقل مطلوب"
                        }
                      />
                    </div>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label> موضوع</label>
                      <TextField
                        name="subject"
                        value={lecture?.subject}
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            subject: e.target.value,
                          });
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick && !lecture.subject && "الموضوع مطلوب حقل"
                        }
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>السعر</label>
                      <TextField
                        name="price"
                        value={lecture?.price ? lecture?.price : null}
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            price: e.target.value,
                          });
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick && !lecture.price && "السعر مطلوب حقل"
                        }
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label> حلقة الوصل</label>
                      <TextField
                        name="link"
                        value={lecture?.link}
                        inputRef={register({
                          pattern: {
                            value: /^(ftp|http|https):\/\/[^ "]+$/,
                            message: "يجب أن يكون الرابط بالشكل الصحيح",
                          },
                        })}
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            link: e.target.value,
                          });
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors error={errors?.link?.message} />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>اسم الطبيب وتخصصه</label>
                      <TextField
                        name="doctorNameAndSpeciality"
                        value={lecture?.doctorNameAndSpeciality}
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            doctorNameAndSpeciality: e.target.value,
                          });
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick &&
                          !lecture.doctorNameAndSpeciality &&
                          "مطلوب حقل اسم الطبيب والتخصص"
                        }
                      />
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>نوع</label>
                      <TextField
                        name="type"
                        select
                        onChange={(e) => {
                          setLecture({
                            ...lecture,
                            type: e.target.value,
                          });
                        }}
                        variant="outlined"
                      >
                        <option
                          key={"DirectLectures"}
                          value={"DirectLectures"}
                          className={classes.optionStyles}
                        >
                          {"محاضرات مباشرة"}
                        </option>
                        <option
                          key={"RecordedLectures"}
                          value={"RecordedLectures"}
                          className={classes.optionStyles}
                        >
                          {"محاضرات مسجلة"}
                        </option>
                      </TextField>
                    </div>
                    <div className={classes.error}>
                      <FieldErrors
                        error={
                          buttonClick && !lecture.type && "نوع الحقل المطلوب"
                        }
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button type="submit" color="info">
                  إرسال
                </Button>
              </CardFooter>
            </form>
            <ToastContainer />
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
