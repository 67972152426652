import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Box, DialogActions, makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from "@material-ui/core";
import SendNotificationModalStyles from "./SendNotificationModalStyles";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(SendNotificationModalStyles);

export default function AccomplishmentsModal({
  setModalOpen,
  modalOpen,
  formInput,
  setFormInput,
  handleCreate,
}) {
  const classes = useStyles();
  const { register, handleSubmit, errors, reset } = useForm();

  return (
    <div>
      <Dialog
        open={modalOpen}
        onClose={() => {
          setModalOpen(!modalOpen);
        }}
        className={classes.root}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(handleCreate)}
          className={classes.hunderPercentWidth}
        >
          <DialogTitle>إرسال إخطار</DialogTitle>

          <DialogContent dividers>
            <Box my={2}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="title"
                label="عنوان"
                name="title"
                autoComplete="title"
                autoFocus
                inputRef={register({
                  required: "العنوان مطلوب!",
                })}
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    title: e.target.value,
                  });
                }}
              />
            </Box>
            <Box my={2}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="message"
                label="رسالة"
                name="body"
                autoComplete="message"
                inputRef={register({
                  required: "الرسالة مطلوبة!",
                })}
                onChange={(e) => {
                  setFormInput({
                    ...formInput,
                    body: e.target.value,
                  });
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Button color="info" variant="contained" type="submit">
              إرسال
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
