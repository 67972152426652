import React from "react";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardFooter from "components/Card/CardFooter.js";
import GridItem from "components/Grid/GridItem.js";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { useForm } from "react-hook-form";
import FieldErrors from "components/FieldErrors";
const useStyles = makeStyles(styles);
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
function FaqsForm({ faqs, setFaqs, handleCreateFaqs }) {
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();

  return (
    <form noValidate onSubmit={handleSubmit(handleCreateFaqs)}>
      <CardHeader color="info">
        <h4 className={classes.cardTitleWhite}>إنشاء أسئلة وأجوبة</h4>
      </CardHeader>
      <CardBody className={classes.cardBody}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.div}>
              <label>سؤال</label>
              <TextField
                defaultValue={faqs?.question}
                name="question"
                onChange={(e) => {
                  setFaqs({
                    ...faqs,
                    question: e.target.value,
                  });
                }}
                inputRef={register({
                  required: "السؤال هو حقل مطلوب",
                })}
                variant="outlined"
              />
            </div>
            <div className={classes.error}>
              <FieldErrors error={errors?.question?.message} />
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.div}>
              <label>إجابه</label>
              <TextField
                defaultValue={faqs?.answer}
                name="answer"
                onChange={(e) => {
                  setFaqs({
                    ...faqs,
                    answer: e.target.value,
                  });
                }}
                inputRef={register({
                  required: "الإجابة هي حقل مطلوب",
                })}
                variant="outlined"
              />
            </div>
            <div className={classes.error}>
              <FieldErrors error={errors?.answer?.message} />
            </div>
          </GridItem>
        </GridContainer>
      </CardBody>
      <CardFooter className={classes.cardJustifyCenter}>
        <Button type="submit" color="info" className={classes.buttonStyles}>
          تحديث
        </Button>
      </CardFooter>
    </form>
  );
}

export default FaqsForm;
