import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import WithdrawlRequestTable from "components/Table/WithdrawlRequestTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList({ requests, setRequests, approveRequest }) {
  let arr = [];
  arr =
    requests.length &&
    requests.map((req) => {
      return [
        req.UserDoctor?.User?.fullName,
        req.UserPatient?.User?.fullName,
        req.startTime,
        req.endTime,
        req.status,
        req.isBooked,
        req.isApproved,
        req.createdAt,
      ];
    });
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>طلبات الانسحاب</h4>
            <h4 className={classes.cardTitleWhite}>Withdrawl Requests</h4>
          </CardHeader>
          <CardBody>
            <WithdrawlRequestTable
              tableHeaderColor="info"
              tableHead={[
                "اسم االمستخدم", // user name
                "الكمية", //amount
                "الإئتمان", // credit
                "دين", //debit,
                "الحالة", //Status,
                "أنشئت في", //created at
              ]}
              tableData={requests}
              approveRequest={approveRequest}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
