import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import AppointmentTable from "components/Table/AppointmentTable";
import { useForm } from "react-hook-form";
import { getDoctorById, editDoctorProfile } from "apis/doctors";
import { useParams } from "react-router-dom";
import { TextField, Chip, Select, Input, FormControl } from "@material-ui/core";
import { compactObj } from "../../utilities";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";
import FieldErrors from "components/FieldErrors";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DoctorProfileStyles from "./DoctorProfileStyles";

const useStyles = makeStyles(DoctorProfileStyles);

export default function DoctorProfile() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [doctor, setDoctor] = useState({});
  const [appointments, setAppointments] = useState({});
  const [specializations, setSpecializations] = useState([]);
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  async function fetchData() {
    try {
      setLoading(true);
      let user = await getDoctorById(id);
      setDoctor(user.doctor);
      setSpecializations(
        user.doctor.UserDoctor.UserDoctorSpecializations.map(
          (ins) => ins.specialization
        )
      );
      setAppointments(user.doctorAppointments);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const notify = (message) => toast(message);
  const updateDoctor = async () => {
    try {
      let data = compactObj(doctor);
      data.specializations = specializations;
      let res = await editDoctorProfile(doctor.id, data);
      notify("تم تحديث ملف تعريف الطبيب بنجاحy");
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  function back() {
    history.push("/rtl/doctors");
  }
  return (
    <div>
      <p className={classes.backStyle} onClick={back}>
        {" "}
        عودة
      </p>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {loading ? null : (
              <form noValidate onSubmit={handleSubmit(updateDoctor)}>
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>تعديل الملف الشخصي</h4>
                </CardHeader>
                <CardBody className={classes.Cardstyle}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>الاسم بالكامل</label>
                        <TextField
                          value={doctor?.fullName}
                          name="fullName"
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              fullName: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "الإسم الكامل ضروري",
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.fullName?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>البريد الإلكتروني</label>
                        <TextField
                          value={doctor?.email}
                          name="email"
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              email: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "البريد الالكتروني مطلوب",
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.email?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>جنس</label>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          value={doctor?.gender}
                          SelectProps={{
                            native: true,
                          }}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              gender: e.target.value,
                            });
                          }}
                        >
                          <option key={"male"} value={"male"}>
                            {"الذكر"}
                          </option>
                          <option key={"female"} value={"female"}>
                            {"أنثى"}
                          </option>
                        </TextField>
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label> مدينة</label>
                        <TextField
                          name="city"
                          value={doctor?.city}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              city: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "مطلوب مدينة!",
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.city?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label> بلد</label>
                        <TextField
                          name="country"
                          value={doctor?.country}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              country: e.target.value,
                            });
                          }}
                          inputRef={register({})}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.country?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>تاريخ الولادة</label>
                        <TextField
                          name="dob"
                          value={doctor?.dob}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              dob: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "تاريخ الميلاد مطلوب!",
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.dob?.message} />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label> عني</label>
                        <TextField
                          name="aboutMe"
                          value={doctor?.UserDoctor?.aboutMe}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              aboutMe: e.target.value,
                            });
                          }}
                          inputRef={register({})}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.aboutMe?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>عنوان العيادة</label>
                        <TextField
                          name="clinicAddress"
                          value={doctor?.UserDoctor?.clinicAddress}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              clinicAddress: e.target.value,
                            });
                          }}
                          inputRef={register({})}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.clinicAddress?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>سعر الاستشارة</label>
                        <TextField
                          name="consultationPrice"
                          value={doctor?.UserDoctor?.consultationPrice}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              consultationPrice: e.target.value,
                            });
                          }}
                          inputRef={register({})}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors
                          error={errors?.consultationPrice?.message}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>سعر التعيين</label>
                        <TextField
                          name="appointmentPrice"
                          value={doctor?.UserDoctor?.appointmentPrice}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              appointmentPrice: e.target.value,
                            });
                          }}
                          inputRef={register({})}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors
                          error={errors?.appointmentPrice?.message}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>عنوان</label>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          value={doctor?.title}
                          SelectProps={{
                            native: true,
                          }}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              title: e.target.value,
                            });
                          }}
                        >
                          <option key={"doctor"} value={"doctor"}>
                            {"طبيب"}
                          </option>
                          <option key={"specialist"} value={"specialist"}>
                            {"متخصص"}
                          </option>
                        </TextField>
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.title?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>الفئة</label>
                        <TextField
                          name="category"
                          value={doctor?.UserDoctor?.category}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              category: e.target.value,
                            });
                          }}
                          inputRef={register({})}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.category?.message} />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>وقت الاستجابة المتوقع</label>
                        <TextField
                          name="expectedResponseTime"
                          value={doctor?.UserDoctor?.expectedResponseTime}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              expectedResponseTime: e.target.value,
                            });
                          }}
                          inputRef={register({})}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors
                          error={errors?.expectedResponseTime?.message}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>رقم الهاتف</label>
                        <TextField
                          value={doctor?.phoneNumber}
                          name="phoneNumber"
                          inputRef={register({
                            required: "البريد الالكتروني مطلوب",
                          })}
                          variant="outlined"
                          disabled
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.phoneNumber?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>تخصص</label>
                        <Autocomplete
                          multiple
                          freeSolo
                          id="tags-outlined"
                          options={specializations}
                          defaultValue={specializations}
                          onChange={(e, newval, reason) => {
                            setSpecializations(newval);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.specialization?.message} />
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter className={classes.buttonStyle}>
                  <Button type="submit" color="info">
                    تحديث
                  </Button>
                </CardFooter>
              </form>
            )}
            <ToastContainer />
          </Card>
        </GridItem>
      </GridContainer>
      <Card>
        <CardHeader color="info">
          <h4 className={classes.cardTitleWhite}>موعد طبيب</h4>
        </CardHeader>
        <AppointmentTable
          tableHeaderColor="info"
          tableHead={[
            "اسم الطبيب", //doctor name
            "اسم المريض", //patient name
            "وقت البدء", //start time
            "وقت النهاية", //end time
            "حالة", //status
            "حجز", //booked
            "وافق", //approved
            "أنشئت في", //created at
          ]}
          tableData={appointments}
        />
      </Card>
    </div>
  );
}
