import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";

import { compactObj } from "../../utilities";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles";

import FaqsForm from "./FaqsForm";

import { Dialog } from "@material-ui/core";
const useStyles = makeStyles(styles);
import { createFaqs } from "apis/auth";
export default function CreateFaqs({ modalOpen, setModalOpen, fetchData }) {
  const classes = useStyles();
  const [faqsLoading, setFaqsLoading] = useState(false);
  const [faqs, setFaqs] = useState({});
  const notify = (message) => toast(message);

  const handleCreateFaqs = async () => {
    try {
      let data = compactObj(faqs);
      let res = await createFaqs(data);
      notify("faqs created successfully");
      setModalOpen(!modalOpen);
      fetchData();
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={4} sm={4} md={6}>
          <Dialog
            open={modalOpen}
            onClose={() => {
              setModalOpen(!modalOpen);
            }}
            className={classes.root}
            fullWidth={true}
            maxWidth={"sm"}
          >
            <Card className={classes.marginBottom0}>
              {faqsLoading ? null : (
                <FaqsForm
                  faqs={faqs}
                  setFaqs={setFaqs}
                  handleCreateFaqs={handleCreateFaqs}
                />
              )}
            </Card>
          </Dialog>
        </GridItem>
        <ToastContainer />
      </GridContainer>
    </div>
  );
}
