import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { getWithdrawRequestById } from "apis/doctors";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  div: {
    display: "flex",
    flexDirection: "column",
  },
  backStyle: {
    marginRight: "30px",
    textDecoration: "underline",
    cursor: "pointer",
  },
};

const useStyles = makeStyles(styles);

export default function WithdrawRequest() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState([]);
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  async function fetchData() {
    try {
      setLoading(true);
      let user = await getWithdrawRequestById(id);
      setRequest(user);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function back() {
    history.push("/rtl/withdraw-requests");
  }
  return (
    <div>
      <p className={classes.backStyle} onClick={back}>
        {" "}
        عودة
      </p>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>
                Withdrawl Request Details
              </h4>
            </CardHeader>
            <CardBody
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {" "}
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <div className={classes.div}>
                    <label> الحالة</label>
                    <TextField
                      name="status"
                      value={request?.status}
                      variant="outlined"
                      onChange={(e) => {
                        setRequest({
                          ...request,
                          status: e.target.value,
                        });
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <div className={classes.div}>
                    <label> تاريخ</label>
                    <TextField
                      name="createdAt"
                      value={request?.createdAt}
                      variant="outlined"
                      onChange={(e) => {
                        setRequest({
                          ...request,
                          createdAt: e.target.value,
                        });
                      }}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <div className={classes.div}>
                    <label>الكمية</label>
                    <TextField
                      name="amount"
                      value={request?.amount}
                      variant="outlined"
                      onChange={(e) => {
                        setRequest({
                          ...request,
                          amount: e.target.value,
                        });
                      }}
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter></CardFooter>
            <ToastContainer />
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
