import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForm } from "react-hook-form";
import { getPatientById, editPatientProfile } from "apis/doctors";
import { useParams } from "react-router-dom";
import { TextField, Select, Grid } from "@material-ui/core";
import { compactObj } from "../../utilities";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppointmentTable from "components/Table/AppointmentTable";
import { useHistory } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PatientProfileStyles from "./patientProfileStyle";

const useStyles = makeStyles(PatientProfileStyles);

export default function PatientProfile() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [patient, setPatient] = useState({});
  const [appointments, setAppointments] = useState({});
  const [symptoms, setSymptoms] = useState([]);
  const history = useHistory();
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { fullName: "asdasd", hello: "hehheh" },
  });

  async function fetchData() {
    try {
      setLoading(true);

      let user = await getPatientById(id);
      setPatient(user.patient);
      setSymptoms(
        user.patient.UserPatient.PatientSymptoms.map((ins) => ins.symptom)
      );
      setAppointments(user.patientAppointments);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const notify = (message) => toast(message);
  const updatePatient = async () => {
    try {
      let data = compactObj(patient);
      data.symptoms = symptoms;
      let res = await editPatientProfile(patient.id, data);
      fetchData();
      notify("تم تحديث ملف المريض بنجاح");
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  function back() {
    history.push("/rtl/patients");
  }
  return (
    <div>
      <p className={classes.backStyle} onClick={back}>
        عودة
      </p>
      {loading ? null : (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <form noValidate onSubmit={handleSubmit(updatePatient)}>
                  <CardHeader color="info">
                    <h4 className={classes.cardTitleWhite}>
                      الملف الشخصي للمريض
                    </h4>
                  </CardHeader>
                  <CardBody className={classes.cardBody}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.div}>
                          <label>الاسم بالكامل</label>
                          <TextField
                            value={patient?.fullName}
                            name="fullName"
                            onChange={(e) => {
                              setPatient({
                                ...patient,
                                fullName: e.target.value,
                              });
                            }}
                            inputRef={register({
                              required: "الاسم بالكامل required",
                              minLength: {
                                value: /^[a-zA-Z ]*$/,
                                message: "الاسم بالكامل just alphabets",
                              },
                            })}
                            variant="outlined"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.div}>
                          <label>البريد الإلكتروني</label>
                          <TextField
                            name="email"
                            value={patient?.email}
                            disable
                            variant="outlined"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.div}>
                          <label>جنس</label>
                          <TextField
                            id="standard-select-currency"
                            variant="outlined"
                            defaultValue="dass"
                            disable
                            value={patient?.gender}
                          ></TextField>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.div}>
                          <label> مدينة</label>
                          <TextField
                            name="city"
                            value={patient?.city}
                            onChange={(e) => {
                              setPatient({
                                ...patient,
                                city: e.target.value,
                              });
                            }}
                            inputRef={register({
                              required: "city is required!",
                            })}
                            variant="outlined"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.div}>
                          <label> بلد</label>
                          <TextField
                            name="country"
                            value={patient?.country}
                            onChange={(e) => {
                              setPatient({
                                ...patient,
                                country: e.target.value,
                              });
                            }}
                            inputRef={register({
                              required: "country is required!",
                            })}
                            variant="outlined"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.div}>
                          <label>عنوان</label>
                          <TextField
                            name="address"
                            value={patient?.UserPatient?.address}
                            onChange={(e) => {
                              setPatient({
                                ...patient,
                                address: e.target.value,
                              });
                            }}
                            inputRef={register({})}
                            variant="outlined"
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.div}>
                          <label>ارتفاع</label>
                          <TextField
                            name="address"
                            value={patient?.UserPatient?.height}
                            onChange={(e) => {
                              setPatient({
                                ...patient,
                                height: e.target.value,
                              });
                            }}
                            inputRef={register({})}
                            variant="outlined"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.div}>
                          <label>وزن</label>
                          <TextField
                            name="weight"
                            value={patient?.UserPatient?.weight}
                            onChange={(e) => {
                              setPatient({
                                ...patient,
                                weight: e.target.value,
                              });
                            }}
                            inputRef={register({})}
                            variant="outlined"
                          />
                        </div>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.div}>
                          <label>أعراض</label>
                          <Autocomplete
                            multiple
                            freeSolo
                            id="tags-outlined"
                            options={symptoms}
                            defaultValue={symptoms}
                            onChange={(e, newval, reason) => {
                              setSymptoms(newval);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" />
                            )}
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <div className={classes.div}>
                          <label>رقم الهاتف</label>
                          <TextField
                            value={patient?.phoneNumber}
                            name="phoneNumber"
                            inputRef={register({
                              required: "البريد الالكتروني مطلوب",
                            })}
                            variant="outlined"
                            disabled
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <Button type="submit" color="info">
                      تحديث
                    </Button>
                  </CardFooter>
                </form>
              </Card>
              <ToastContainer />
            </GridItem>
          </GridContainer>
          <Card>
            <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>مواعيد المريض</h4>
            </CardHeader>
            <AppointmentTable
              tableHeaderColor="info"
              tableHead={[
                "اسم الطبيب", //doctor name
                "اسم المريض", //patient name
                "وقت البدء", //start time
                "وقت النهاية", //end time
                "حالة", //status
                "حجز", //booked
                "وافق", //approved
                "أنشئت في", //created at
              ]}
              tableData={appointments}
            />
          </Card>
        </>
      )}
    </div>
  );
}
