const ModeratorStyles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  cardJustifyCenter: {
    justifyContent: "center",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    marginTop: "25px",
  },
  gridContainer: {
    marginTop: "5px",
  },
  cardBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "180px",
  },
  label: {
    fontSize: "12px",
    cursor: "pointer",
    color: "#006FFF",
    marginLeft: "10px",
  },
  box: {
    marginLeft: "20px",
    cursor: "pointer",
  },
  labelTwo: {
    marginLeft: "10px",
    color: "#201E1E",
  },
  iconStyle: {
    marginRight: "8px",
  },
  termsAndConditionsImage: {
    maxWidth: "100%",
    height: "auto",
  },
  moderatorModelCard: {
    marginBottom: "0px",
  },
  moderatorModelCardFooter: {
    margin: 0,
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  cardStyles: {
    marginTop: "10px",
  },
  buttonStyles: {
    marginLeft: "60px",
  },
  inputStyles: {
    display: "none",
  },
  footer: {
    overflow: "auto",
    padding: "0 15px",
  },
  cardFooterStyles: {
    overflow: "auto",
    height: "145px",
  },
  buttonStyles: {
    marginTop: "30px",
    marginRight: "-20px",
  },
  marginBottom0: {
    marginBottom: 0,
  },
});

export default ModeratorStyles;
