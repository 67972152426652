import axios from "./axios-configured";

export const createNewLecture = async (payload) => {
  return axios.post(`/lecture`, payload);
};

export const fetchLectures = async () => {
  return axios.get(`/lecture`);
};

export const getSignedUrl = async (name) => {
  return axios.get(`/media/signed-url?extension=${name}`);
};

export const getLectureById = async (id) => {
  return axios.get(`/lecture/${id}`);
};

export const deleteLecture = async (id) => {
  return axios.get(`/lecture/${id}`);
};

export const updateLecture = async (id, payload) => {
  return axios.put(`/lecture/${id}`, payload);
};
