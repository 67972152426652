import React, { useState, useEffect } from "react";
import List from "./List";
import { fetchModerators } from "../../../apis/admins";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Moderators() {
  const [loading, setLoading] = useState(false);
  const [moderators, setModerators] = useState([]);
  const notify = (message) => toast(message);

  async function fetchData() {
    try {
      setLoading(true);
      let moderators = await fetchModerators();
      setModerators([...moderators]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify("خطأ أثناء تحميل الوسطاء!");
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <List
        moderators={moderators}
        setModerators={setModerators}
        fetchData={fetchData}
        loading={loading}
      />
      <ToastContainer />
    </div>
  );
}

export default Moderators;
