import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from "components/CustomButtons/Button.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { useHistory } from "react-router";
import ConfirmationPopper from "components/ConfirmationPopper";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const history = useHistory();
  const classes = useStyles();

  const { tableHead, tableData, tableHeaderColor, blockDoctor } = props;

  const showLecture = (id) => {
    history.push(`/rtl/lecture/details/${id}`);
  };
  const popoverOpen = Boolean(popoverAnchorEl);

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const [activeRow, setActiveRow] = React.useState(null);

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  return (
    <div className={classes.tableResponsive}>
      <Button
        type="submit"
        color="info"
        onClick={() => {
          history.push("/rtl/lecture/create");
        }}
      >
        اضف جديد
      </Button>
      {tableData.length ? (
        <>
          <Table className={classes.table}>
            {tableHead !== undefined ? (
              <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                <TableRow className={classes.tableHeadRow}>
                  {tableHead.map((prop, key) => {
                    return (
                      <TableCell
                        className={
                          classes.tableCell + " " + classes.tableHeadCell
                        }
                        key={key}
                      >
                        {prop}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {tableData.length &&
                tableData.map((prop, key) => {
                  return (
                    <TableRow
                      key={key}
                      className={classes.tableBodyRow}
                      style={{
                        cursor: "pointer",
                        pointerEvents: "auto",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => showLecture(prop?.id)}
                      >
                        <span
                          title={prop?.subTitle}
                          className={classes.wrapTextIntoTwoLines}
                        >
                          {prop?.title || "----"}
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => showLecture(prop?.id)}
                      >
                        <span
                          title={prop?.subTitle}
                          className={classes.wrapTextIntoTwoLines}
                        >
                          {prop?.subTitle || "----"}
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => showLecture(prop?.id)}
                      >
                        {prop?.audience || "----"}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => showLecture(prop?.id)}
                      >
                        <span
                          title={prop?.subTitle}
                          className={classes.wrapTextIntoTwoLines}
                        >
                          {prop?.description || "----"}
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => showLecture(prop?.id)}
                      >
                        {prop?.doctorNameAndSpeciality || "----"}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => showLecture(prop?.id)}
                      >
                        <span
                          title={prop?.subTitle}
                          className={classes.wrapTextIntoTwoLines}
                        >
                          {prop?.subject || "----"}
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => showLecture(prop?.id)}
                      >
                        <span
                          title={prop?.subTitle}
                          className={classes.wrapTextIntoTwoLines}
                        >
                          {prop?.link || "----"}
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => showLecture(prop?.id)}
                      >
                        {prop.price || "----"}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => showLecture(prop?.id)}
                      >
                        {prop.type || "----"}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <ConfirmationPopper
              open={popoverOpen}
              anchorEl={popoverAnchorEl}
              handlePopoverClose={handlePopoverClose}
              activeRow={activeRow}
              setActiveRow={setActiveRow}
              blockDoctor={blockDoctor}
            />
          </Table>
        </>
      ) : (
        <div className={classes.center}>لا توجد محاضرات متاحة</div>
      )}
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
