import Dashboard from "@material-ui/icons/Dashboard";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import RateReviewIcon from "@material-ui/icons/RateReview";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import SettingsIcon from "@material-ui/icons/Settings";
import Language from "@material-ui/icons/Language";

// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";
import Patients from "views/TableList/Patients/index.js";
import Doctors from "views/TableList/Doctors/index.js";
import Appointments from "views/TableList/Appointments/index.js";
import Lectures from "views/TableList/Lectures/index.js";
import Reviews from "views/TableList/Reviews/index.js";
import Transactions from "views/TableList/Transactions/index.js";
import Trans from "views/TableList/Trans/index.js";
import WithdrawlRequests from "views/TableList/WithdrawlRequests/index.js";
import WithdrawlRequestDetails from "views/DetailPages/WithdrawlRequestDetails.js";
import TransactionDetails from "views/DetailPages/TransactionDetails";
import AppointmentDetails from "views/DetailPages/AppointmentDetails";
import DoctorProfile from "views/DetailPages/DoctorProfile";
import PatientProfile from "views/DetailPages/PatientProfile";
import CreateLecture from "views/DetailPages/CreateLecture";
import LectureDetails from "views/DetailPages/LectureDetails";
import CreateDoctor from "views/DetailPages/CreateDoctor";
import CreateModerator from "views/DetailPages/CreateModerator";
import CreateFaqs from "views/DetailPages/CreateFaqs";
import Banners from "views/TableList/Banners";
import Queries from "views/TableList/Queries";
import BannerForm from "views/DetailPages/BannerForm";
import TermsAndConditionsForm from "views/DetailPages/TermsAndConditionsForm";
import EditBanner from "views/DetailPages/EditBanner";
import Moderators from "views/TableList/Moderators";
import Faqs from "views/TableList/Faqs";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import AssignmentIcon from "@material-ui/icons/Assignment";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import CreateIcon from "@material-ui/icons/Create";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import QueryDetails from "views/DetailPages/QueryDetails";
import Settings from "views/DetailPages/Settings";

const dashboardRoutes = [
  {
    path: "/rtl-page",
    rtlName: "لوحة القيادة",
    icon: Language,
    component: RTLPage,
    layout: "/rtl",
  },
  {
    path: "/doctors",
    rtlName: "المختصون",
    icon: LocalHospitalIcon,
    component: Doctors,
    layout: "/rtl",
  },
  {
    path: "/patients",
    rtlName: "العملاء",
    icon: AccessibilityIcon,
    component: Patients,
    layout: "/rtl",
  },
  {
    path: "/appointments",
    rtlName: "تعيينات",
    icon: AssignmentIcon,
    component: Appointments,
    layout: "/rtl",
  },
  {
    path: "/wallets",
    rtlName: "محفظة جيب",
    icon: AccountBalanceWalletIcon,
    component: Transactions,
    layout: "/rtl",
  },
  {
    path: "/transactions",
    rtlName: "المعاملات",
    icon: AccountBalanceIcon,
    component: Trans,
    layout: "/rtl",
  },
  {
    path: "/withdraw-requests",
    rtlName: "طلبات الانسحاب",
    icon: LocalAtmIcon,
    component: WithdrawlRequests,
    layout: "/rtl",
  },
  {
    path: "/reviews",
    rtlName: "المراجعات",
    icon: RateReviewIcon,
    component: Reviews,
    layout: "/rtl",
  },
  {
    path: "/moderators",
    rtlName: "الوسطاء",
    icon: VerifiedUserIcon,
    component: Moderators,
    layout: "/rtl",
  },
  {
    path: "/faqs",
    rtlName: "أسئلة وأجوبة",
    icon: LiveHelpIcon,
    component: Faqs,
    layout: "/rtl",
  },
  {
    path: "/withraw-request/details/:id",
    rtlName: "طلبات الانسحاب",
    icon: Dashboard,
    component: WithdrawlRequestDetails,
    layout: "/rtl",
  },
  {
    path: "/transaction/details/:id",
    rtlName: "طلبات الانسحاب",
    icon: Dashboard,
    component: TransactionDetails,
    layout: "/rtl",
  },
  {
    path: "/appointment/details/:id",
    rtlName: "طلبات الانسحاب",
    icon: Dashboard,
    component: AppointmentDetails,
    layout: "/rtl",
  },
  {
    path: "/doctor/profile/:id",
    rtlName: "طلبات الانسحاب",
    icon: Dashboard,
    component: DoctorProfile,
    layout: "/rtl",
  },
  {
    path: "/patient/profile/:id",
    rtlName: "طلبات الانسحاب",
    icon: Dashboard,
    component: PatientProfile,
    layout: "/rtl",
  },
  {
    path: "/lectures",
    rtlName: "محاضرات",
    icon: ImportContactsIcon,
    component: Lectures,
    layout: "/rtl",
  },
  {
    path: "/lecture/create",
    rtlName: "إنشاء محاضر",
    icon: CreateIcon,
    component: CreateLecture,
    layout: "/rtl",
  },
  {
    path: "/doctor/create",
    rtlName: "إنشاء طبيب",
    icon: CreateIcon,
    component: CreateDoctor,
    layout: "/rtl",
  },
  {
    path: "/moderator/create",
    rtlName: "إنشاء وسيط",
    icon: CreateIcon,
    component: CreateModerator,
    layout: "/rtl",
  },
  {
    path: "/faq/create",
    rtlName: "إنشاء أسئلة وأجوبة",
    icon: CreateIcon,
    component: CreateFaqs,
    layout: "/rtl",
  },
  {
    path: "/banners/create",
    rtlName: "إنشاء لافتة",
    icon: CreateIcon,
    component: BannerForm,
    layout: "/rtl",
  },
  {
    path: "/terms-and-conditions/create",
    rtlName: "الأحكام والشروط",
    icon: IndeterminateCheckBoxIcon,
    component: TermsAndConditionsForm,
    layout: "/rtl",
  },
  {
    path: "/lecture/details/:id",
    rtlName: "تفاصيل المحاضرة",
    icon: Dashboard,
    component: LectureDetails,
    layout: "/rtl",
  },
  {
    path: "/banners",
    rtlName: "لافتات",
    icon: Dashboard,
    component: Banners,
    layout: "/rtl",
  },
  {
    path: "/queries",
    rtlName: "استفسارات",
    icon: Dashboard,
    component: Queries,
    layout: "/rtl",
  },
  {
    path: "/query/details/:id",
    rtlName: "تفاصيل الاستعلام",
    icon: Dashboard,
    component: QueryDetails,
    layout: "/rtl",
  },
  {
    path: "/banner/details/:id",
    rtlName: "banner detail",
    icon: Dashboard,
    component: EditBanner,
    layout: "/rtl",
  },
  {
    path: "/settings",
    rtlName: "الإعدادات",
    icon: SettingsIcon,
    component: Settings,
    layout: "/rtl",
  },
];

export default dashboardRoutes;
