import React, { useState, useEffect } from "react";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardFooter from "components/Card/CardFooter.js";
import GridItem from "components/Grid/GridItem.js";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import EditBannerStyles from "./EditBannerStyles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "components/Card/Card.js";
import { fetchSettings, upsertSettings } from "apis/admins";
import FieldErrors from "components/FieldErrors";

const useStyles = makeStyles(EditBannerStyles);
function Settings() {
  const classes = useStyles();
  const { register, handleSubmit, errors, reset } = useForm();
  const [loading, setLoading] = useState(false);

  const notify = (message) => toast(message);

  async function fetchData() {
    try {
      let res = await fetchSettings();
      reset({ commision: res.commision, sessionTime: res.sessionTime });
    } catch (error) {}
  }

  const handleSettings = async (data) => {
    setLoading(true);
    try {
      let res = await upsertSettings(data);
      notify("تم تحديث البانر بنجاح");
      setLoading(false);
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const height = { height: "200px" };
  return (
    <div>
      <GridContainer>
        <GridItem xs={4} sm={4} md={6}>
          <Card>
            <form onSubmit={handleSubmit(handleSettings)}>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>الإعدادات</h4>
              </CardHeader>
              <CardBody className={classes.cardBody} style={height}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.div}>
                      <label>العمولة (%)</label>
                      <TextField
                        name="commision"
                        inputRef={register({
                          required: "العمولة المطلوبة",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "يجب أن تكون العمولة في رقم",
                          },
                        })}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors error={errors?.commision?.message} />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.div}>
                      <label>وقت الجلسة (دقائق)</label>
                      <TextField
                        name="sessionTime"
                        inputRef={register({
                          required: "وقت الجلسة المطلوب",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "يجب أن يكون وقت الجلسة رقمًا",
                          },
                        })}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.error}>
                      <FieldErrors error={errors?.sessionTime?.message} />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button type="submit" color="info" disabled={loading}>
                  تحديث
                </Button>
                <ToastContainer />
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Settings;
