import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ModeratorsTable from "components/Table/ModeratorsTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function TableList({
  moderators,
  setModerators,
  fetchData,
  loading,
}) {
  let arr = moderators.map((lecture) => {
    return [
      lecture.fullName,
      lecture.email,
      lecture.gender,
      lecture.city,
      moment(lecture.createdAt).fromNow(),
    ];
  });
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>الوسطاء</h4>
            <h4 className={classes.cardTitleWhite}>Moderators</h4>
          </CardHeader>
          <CardBody>
            <ModeratorsTable
              tableHeaderColor="info"
              tableHead={[
                "البريد الإلكتروني", //email
                "نوع", // type
                "أنشئت في", //created at
                "تم التحديث في", //updated at
                "أجراءات", //actions
              ]}
              tableData={moderators}
              fetchData={fetchData}
              loading={loading}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
