import * as React from "react";
import classes from "./TableLoading.module.css";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Skeleton } from "@material-ui/lab";

function TableLoading({ tableHead }) {
  return (
    <TableRow className={classes.tableBodyRow}>
      {tableHead.map((val, index) => (
        <TableCell className={classes.tableCell} key={index}>
          <Skeleton variant="rect" height={20} width={100} />
        </TableCell>
      ))}
    </TableRow>
  );
}

export default TableLoading;
