import React, { useState, useEffect } from "react";
import List from "./List";
import { fetchTransactions } from "../../../apis/doctors";

function Transactions() {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransaction] = useState([]);

  async function fetchData() {
    try {
      setLoading(true);
      let requests = await fetchTransactions();
      setTransaction([...requests]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <List
        transactions={transactions}
        setTransaction={setTransaction}
        loading={loading}
      />
    </div>
  );
}

export default Transactions;
