import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import FaqsTable from "components/Table/FaqsTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function TableList({ faqs, setFaqs, fetchData, loading }) {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>أسئلة وأجوبة</h4>
            <h4 className={classes.cardTitleWhite}>Faqs</h4>
          </CardHeader>
          <CardBody>
            <FaqsTable
              tableHeaderColor="info"
              tableHead={[
                "سؤال", //question
                "إجابه", // answer
                "أنشئت في", //created at
                "تم التحديث في", //updated at
              ]}
              tableData={faqs}
              fetchData={fetchData}
              loading={loading}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
