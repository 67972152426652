import React, { useState, useEffect } from "react";
import WithdrawlRequestsList from "./WithdrawlRequestsList";
import {
  fetchWithdrawRequests,
  acceptWithdrawRequest,
} from "../../../apis/doctors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function WithdrawlRequests() {
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const notify = (message) => toast(message);

  async function fetchData() {
    try {
      setLoading(true);
      let requests = await fetchWithdrawRequests();
      setRequests([...requests]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify("خطأ أثناء تحميل المختصون!");
    }
  }
  const approveRequest = async (request) => {
    try {
      let res = await acceptWithdrawRequest(request.id);
      notify("تم قبول الطلب بنجاح!");
      fetchData();
    } catch (error) {
      setRequests([...requests]);
      notify("خطأ أثناء الموافقة على الطلب!");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <WithdrawlRequestsList
        requests={requests}
        setRequests={setRequests}
        approveRequest={approveRequest}
      />
      <ToastContainer />
    </div>
  );
}

export default WithdrawlRequests;
