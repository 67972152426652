import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForm } from "react-hook-form";
import { createDoctor } from "apis/doctors";
import { TextField } from "@material-ui/core";
import { compactObj } from "../../utilities";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useHistory } from "react-router";
import FieldErrors from "components/FieldErrors";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  div: {
    display: "flex",
    flexDirection: "column",
  },
  gridContainer: {
    marginTop: "5px",
  },
  backStyle: {
    marginRight: "30px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  error: {
    marginBottom: "20px",
  },
  cardBodyStyles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "470px",
  },
};

const useStyles = makeStyles(styles);
export default function CreateDoctor() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [doctor, setDoctor] = useState({});
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const notify = (message) => toast(message);
  const handleCreate = async () => {
    try {
      doctor.type = "doctor";
      let data = compactObj(doctor);
      let res = await createDoctor(data);
      notify("تم إنشاء الطبيب بنجاح");
      history.push("/rtl/doctors");
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
    }
  };

  function back() {
    history.push("/rtl/doctors");
  }
  console.log(doctor);

  return (
    <div>
      <p className={classes.backStyle} onClick={back}>
        {" "}
        عودة
      </p>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {loading ? null : (
              <form
                noValidate
                onSubmit={handleSubmit(handleCreate)}
                disabled="disabled"
              >
                <CardHeader color="info">
                  <h4 className={classes.cardTitleWhite}>تعديل الملف الشخصي</h4>
                </CardHeader>
                <CardBody className={classes.cardBodyStyles}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>الاسم بالكامل</label>
                        <TextField
                          defaultValue={doctor?.fullName}
                          name="fullName"
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              fullName: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "حقل الاسم مطلوب",
                            pattern: {
                              value: /^[a-zA-Z ]*$/,
                              message:
                                "يمكن أن يحتوي حقل الاسم على أبجديات فقط",
                            },
                            minLength: {
                              value: 8,
                              message:
                                "يجب ألا يقل الاسم الكامل عن ثمانية أحرف",
                            },
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.fullName?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>البريد الإلكتروني</label>
                        <TextField
                          defaultValue={doctor?.email}
                          name="email"
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              email: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "حقل البريد الإلكتروني مطلوب",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "لقد أدخلت عنوان بريد إلكتروني غير صالح",
                            },
                            minLength: {
                              value: 7,
                              message: "عنوان البريد الإلكتروني غير صالح",
                            },
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.email?.message} />
                      </div>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>جنس</label>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          defaultValue={doctor?.gender}
                          SelectProps={{
                            native: true,
                          }}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              gender: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "gender is required!",
                          })}
                        >
                          <option key={"male"} value={"male"}>
                            {"الذكر"}
                          </option>
                          <option key={"female"} value={"female"}>
                            {"أنثى"}
                          </option>
                        </TextField>
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label> مدينة</label>
                        <TextField
                          name="city"
                          defaultValue={doctor?.city}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              city: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "مطلوب مدينة!",
                            pattern: {
                              value: /^[a-zA-Z ]*$/,
                              message:
                                "يمكن أن تحتوي المدينة على الأبجديات فقط",
                            },
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.city?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label> بلد</label>
                        <TextField
                          name="country"
                          defaultValue={doctor?.country}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              country: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "الدولة مطلوبة",
                            pattern: {
                              value: /^[a-zA-Z ]*$/,
                              message:
                                "يمكن أن تحتوي الدولة على أحرف أبجدية فقط",
                            },
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.country?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>تاريخ الولادة</label>
                        <TextField
                          id="date"
                          type="date"
                          defaultValue={moment(doctor?.dob)}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              dob: e.target.value,
                            });
                          }}
                          inputRef={register({ required: "التاريخ مطلوب" })}
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.date?.message} />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label> عني</label>
                        <TextField
                          name="aboutMe"
                          defaultValue={doctor?.UserDoctor?.aboutMe}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              aboutMe: e.target.value,
                            });
                          }}
                          inputRef={register({ required: "مطلوب معلومات عني" })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.aboutMe?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>عنوان العيادة</label>
                        <TextField
                          name="clinicAddress"
                          defaultValue={doctor?.UserDoctor?.clinicAddress}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              clinicAddress: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "عنوان العيادة مطلوب",
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.clinicAddress?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>سعر الاستشارة</label>
                        <TextField
                          name="consultationPrice"
                          defaultValue={doctor?.UserDoctor?.consultationPrice}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              consultationPrice: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "السعر التشاور مطلوب",
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "يجب أن يكون سعر الاستشارة  أرقامًا",
                            },
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors
                          error={errors?.consultationPrice?.message}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>سعر التعيين</label>
                        <TextField
                          name="appointmentPrice"
                          defaultValue={doctor?.UserDoctor?.appointmentPrice}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              appointmentPrice: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "السعر مطلوب",
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "يجب أن يكون سعر الموعد أرقامًا.",
                            },
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors
                          error={errors?.appointmentPrice?.message}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>عنوان</label>
                        <TextField
                          name="title"
                          defaultValue={doctor?.UserDoctor?.title}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              title: e.target.value,
                            });
                          }}
                          inputRef={register({ required: "العنوان مطلوب" })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.title?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>الفئة</label>
                        <TextField
                          name="category"
                          defaultValue={doctor?.UserDoctor?.category}
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              category: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "اختيار القسم مطلوب",
                            pattern: {
                              value: /^[a-zA-Z ]*$/,
                              message: "يجب أن يحتوي حقل الفئة على أبجديات",
                            },
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.category?.message} />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>وقت الاستجابة المتوقع</label>
                        <TextField
                          name="expectedResponseTime"
                          defaultValue={
                            doctor?.UserDoctor?.expectedResponseTime
                          }
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              expectedResponseTime: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "مطلوب رقم وقت الاستجابة المتوقع",
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors
                          error={errors?.expectedResponseTime?.message}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>رقم الهاتف</label>
                        <TextField
                          defaultValue={doctor?.phoneNumber}
                          name="phoneNumber"
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              phoneNumber: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "رقم الهاتف مطلوب",
                            pattern: {
                              value: /^(\+966)?0?5[0-9]{8}$/g,
                              message: "صيغة رقم الهاتف غير صحيحة",
                            },
                            minLength: {
                              value: 8,
                              message:
                                "يجب أن يكون طول رقم الهاتف ثمانية على الأقل",
                            },
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.phoneNumber?.message} />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.div}>
                        <label>كلمه السر</label>
                        <TextField
                          defaultValue={doctor?.password}
                          name="password"
                          onChange={(e) => {
                            setDoctor({
                              ...doctor,
                              password: e.target.value,
                            });
                          }}
                          inputRef={register({
                            required: "كلمة المرور مطلوبة",
                            pattern: {
                              value: /^[a-zA-Z0-9]+$/,
                              message:
                                "يمكن أن تحتوي كلمة المرور على أحرف أبجدية وأرقام فقط",
                            },
                            minLength: {
                              value: 8,
                              message:
                                "يجب أن تكون كلمة المرور 8 أحرف على الأقل",
                            },
                          })}
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.error}>
                        <FieldErrors error={errors?.password?.message} />
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button type="submit" color="info">
                    تحديد
                  </Button>
                </CardFooter>
              </form>
            )}
            <ToastContainer />
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
