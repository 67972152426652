import React, { useState, useEffect } from "react";
import BannersList from "./BannersList";
import { getListOfBanners, editBanner } from "../../../apis/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Banners() {
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const notify = (message) => toast(message);

  async function fetchData() {
    try {
      setLoading(true);
      let banners = await getListOfBanners();
      setBanners([...banners]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify("error loading Banners");
    }
  }
  const approveBanner = async (banner) => {
    try {
      await editBanner(banner.id, {
        status: !banner.status,
      });
      if (banner.status === true) {
        notify("تم تحديث حالة البانر بنجاح");
      } else {
        notify("تم تحديث حالة البانر بنجاح");
      }
      fetchData();
    } catch (error) {
      console.log(error);
      notify("خطأ أثناء الموافقة على البانر!");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <BannersList
        banners={banners}
        setBanners={setBanners}
        approveBanner={approveBanner}
        loading={loading}
      />
      <ToastContainer />
    </div>
  );
}

export default Banners;
