import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { useHistory } from "react-router";
const useStyles = makeStyles(styles);
export default function CustomTable(props) {
  const history = useHistory();
  const classes = useStyles();
  const { tableHead, tableData, tableHeaderColor, approveRequest } = props;
  const showRequest = (id) => {
    history.push(`/rtl/withraw-request/details/${id}`);
  };
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((prop, key) => {
              return (
                <TableRow
                  key={key}
                  className={classes.tableBodyRow}
                  style={{
                    cursor: "pointer",
                    pointerEvents: "auto",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => showRequest(prop?.id)}
                  >
                    {prop?.User?.fullName || "----"}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => showRequest(prop?.id)}
                  >
                    {prop?.amount || "----"}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => showRequest(prop?.id)}
                  >
                    {prop?.Wallet?.credit || "----"}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => showRequest(prop?.id)}
                  >
                    {prop?.Wallet?.debit || "----"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {prop?.status === "completed" ? (
                      prop?.status
                    ) : (
                      <Switch
                        checked={prop?.isBlocked}
                        onChange={() => {
                          approveRequest(prop);
                        }}
                        name="checkedA"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => showRequest(prop?.id)}
                  >
                    {moment(prop?.createdAt).fromNow()}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <span>لم يتم العثور على العناصر</span>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};
CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
