import React, { useState, useEffect } from "react";
import PatientsList from "./PatientsList";
import { fetchPatients, blockUser, approveUser } from "../../../apis/patients";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Patients() {
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const notify = (message) => toast(message);

  async function fetchData() {
    try {
      setLoading(true);
      let patients = await fetchPatients();
      setPatients([...patients]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const blockPatient = async (user) => {
    try {
      let res = await blockUser(
        user.id,
        user.isBlocked === true ? false : true
      );
      if (user.isBlocked === true) {
        notify("تم إلغاء حظر المريض بنجاح");
      } else {
        notify("تم حظر المريض بنجاح");
      }
      fetchData();
    } catch (error) {
      user.isBlocked = !user.isBlocked;
      setPatients([...patients]);
      notify("خطأ أثناء حظر / إلغاء حظر المستخدم!");
    }
  };

  const approvePatient = async (user) => {
    try {
      await approveUser(user.id, user.isApproved === "1" ? "0" : "1");
      if (user.isApproved === "1") {
        notify("لم تتم الموافقة على المريض بنجاح");
      } else {
        notify("تمت الموافقة على المريض بنجاح ");
      }
      fetchData();
    } catch (error) {
      user.isApproved = !user.isApproved;
      setPatients([...patients]);
      notify("خطأ أثناء الموافقة على المستخدم!");
    }
  };

  return (
    <div>
      <PatientsList
        patients={patients}
        setPatients={setPatients}
        blockPatient={blockPatient}
        approvePatient={approvePatient}
      />
      <ToastContainer />
    </div>
  );
}

export default Patients;
