export function getQueryParams(query = "") {
  const params = query?.split("?")[1];
  const paramsArr = params?.split("&");
  let paramsObj = {};
  paramsArr?.length &&
    paramsArr.map((param, index) => {
      const arr = param.split("=");
      paramsObj = {
        ...paramsObj,
        [arr[0]]: arr[1],
      };
    });
  return paramsObj;
}

export const compactObj = (obj) => {
  let compactObj = {};
  for (let key in obj) {
    if (obj[key] && !Array.isArray(obj[key]) && !(obj[key] instanceof Object)) {
      Object.assign(compactObj, {
        [key]: obj[key],
      });
    } else if (Array.isArray(obj[key]) && obj[key].length) {
      Object.assign(compactObj, {
        [key]: obj[key],
      });
    } else if (
      obj[key] instanceof Object &&
      Object.keys(obj[key]) &&
      Object.keys(obj[key]).length
    ) {
      Object.assign(compactObj, {
        [key]: obj[key],
      });
    }
  }
  return compactObj;
};
