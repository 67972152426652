import React from "react";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardFooter from "components/Card/CardFooter.js";
import GridItem from "components/Grid/GridItem.js";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { useForm } from "react-hook-form";
import FieldErrors from "components/FieldErrors";
import { makeStyles } from "@material-ui/core/styles";
import AboutUsStyles from "./AboutUsStyles";
import { fetchAboutUs } from "apis/doctors";
const useStyles = makeStyles(AboutUsStyles);

function AboutUsForm({ aboutUs, setAboutUs, handleCreateAboutUs }) {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  React.useEffect(() => {
    async function getAboutUs() {
      try {
        let res = await fetchAboutUs();

        setAboutUs(res);
      } catch (err) {}
    }
    getAboutUs();
  }, []);
  return (
    <form noValidate onSubmit={handleSubmit(handleCreateAboutUs)}>
      <CardHeader color="info">
        <h4 className={classes.cardTitleWhite}>معلومات عنا</h4>
      </CardHeader>
      <div className={`${classes.container} ${classes.containerPadding}`}>
        <div className={classes.container}>
          <TextField
            value={aboutUs?.text}
            multiline={true}
            name="text"
            onChange={(e) => {
              setAboutUs({
                ...aboutUs,
                text: e.target.value,
              });
            }}
            inputRef={register({
              required: "نص من نحن هو حقل مطلوب",
            })}
            variant="outlined"
            multiline
            rows={4}
          />
          {errors && (
            <span className={classes.error}>{errors?.text?.message}</span>
          )}
        </div>

        <Button className={classes.buttonStyle} type="submit" color="info">
          تحديث
        </Button>
      </div>
    </form>
  );
}

export default AboutUsForm;
