import axios from "./axios-configured";

export const fetchPatients = async () => {
  return axios.get(`/admin/patients`);
};
export const blockUsers = async () => {
  return axios.get(`/admin/blockPatients`);
};

export const blockUser = async (userId, block) => {
  return axios.put(`/admin/block/${userId}`, { block });
};

export const approveUser = async (userId, approvedVal) => {
  return axios.put(`/admin/approve/${userId}`, { approve: approvedVal });
};
