import { Box, Button, Card, Popper, Typography } from "@material-ui/core";
import * as React from "react";
export default function ConfirmationPopper ({
  open,
  anchorEl,
  handlePopoverClose,
  activeRow,
  setActiveRow,
  blockDoctor
}) {
  async function deleteClickHandler () {
    try {
      // await delete({ id: activeRow?._id });
    } catch (error) {}
  }
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement='top'
      style={{ zIndex: 10 }}
      // disablePortal={true}
      modifiers={{
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: "scrollParent",
        },
        arrow: {
          enabled: false,
          element: anchorEl,
        },
      }}
    >
      <Card
        style={{
          padding: "23px 20px 16px 20px",
          width: "260px",
          border: "1px solid #f3f3f3",
        }}
      >
        <Typography>Are you sure you want to delete this user?</Typography>
        <Box display='flex' justifyContent='flex-end'>
          <Button
            onClick={e => {
              setActiveRow(null);
              handlePopoverClose(e);
            }}
          >
            Cancel
          </Button>
          <Button onClick={()=>{blockDoctor(activeRow)}}>Delete</Button>
        </Box>
      </Card>
    </Popper>
  );
}
