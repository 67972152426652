import React, { useState, useEffect } from "react";
import List from "./List";
import { fetchFaqs } from "../../../apis/admins";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Faqs() {
  const [loading, setLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const notify = (message) => toast(message);

  async function fetchData() {
    try {
      setLoading(true);
      let faqs = await fetchFaqs();
      setFaqs([...faqs]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify("خطأ أثناء تحميل الأسئلة الشائعة!");
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <List
        faqs={faqs}
        setFaqs={setFaqs}
        fetchData={fetchData}
        loading={loading}
      />
      <ToastContainer />
    </div>
  );
}

export default Faqs;
