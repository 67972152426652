import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { useHistory } from "react-router";
import Button from "components/CustomButtons/Button.js";

import CreateFaqs from "views/DetailPages/CreateFaqs";
import TableLoading from "components/TableLoading";
const useStyles = makeStyles(styles);

export default function FaqsTable(props) {
  const history = useHistory();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);

  const {
    tableHead,
    tableData,
    tableHeaderColor,
    approveBanner,
    fetchData,
    loading,
  } = props;

  const showBanner = (id) => {
    history.push(`/rtl/banner/details/${id}`);
  };

  return (
    <div className={classes.tableResponsive}>
      <Button
        type="submit"
        color="info"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        اضف جديد
      </Button>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {loading ? (
            <TableLoading tableHead={tableHead} />
          ) : tableData.length > 0 ? (
            tableData.map((prop, key) => {
              return (
                <TableRow key={key} className={classes.tableBodyRow}>
                  <TableCell className={classes.tableCell}>
                    {prop?.question || "----"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {prop?.answer || "----"}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => showBanner(prop?.id)}
                  >
                    {moment(prop?.createdAt).fromNow()}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    onClick={() => showBanner(prop?.id)}
                  >
                    {moment(prop?.updatedAt).fromNow()}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <div> لا توجد أسئلة وأجوبة</div>
          )}
        </TableBody>
      </Table>
      <CreateFaqs
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        fetchData={fetchData}
      />
    </div>
  );
}

FaqsTable.defaultProps = {
  tableHeaderColor: "gray",
};

FaqsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
