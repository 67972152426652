import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForm } from "react-hook-form";
import { getAppointmentById, editAppointment } from "apis/doctors";
import { useParams } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { compactObj } from "../../utilities";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  div: {
    display: "flex",
    flexDirection: "column",
  },
  backStyle: {
    marginRight: "30px",
    textDecoration: "underline",
    cursor: "pointer",
  },
};

const useStyles = makeStyles(styles);

export default function AppointmentDetails() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [appointment, setAppointment] = useState([]);
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  async function fetchData() {
    try {
      setLoading(true);
      let user = await getAppointmentById(id);
      setAppointment(user);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const notify = (message) => toast(message);
  const updateAppointment = async () => {
    try {
      let data = compactObj(appointment);
      let res = await editAppointment(appointment.id, data);
      notify("appointment details updated successfully");
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  function back() {
    history.push("/rtl/appointments");
  }
  return (
    <div>
      <p className={classes.backStyle} onClick={back}>
        {" "}
        عودة
      </p>
      <form onSubmit={handleSubmit(updateAppointment)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>Edit Appointment</h4>
              </CardHeader>
              <CardBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "200px",
                }}
              >
                {" "}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>طبيب المستخدم</label>
                      <TextField
                        name="name"
                        disabled
                        value={appointment?.userDoctor?.fullName}
                        inputRef={register({
                          required: "الاسم بالكامل required",
                          minLength: {
                            value: /^[a-zA-Z ]*$/,
                            message: "الاسم بالكامل just alphabets",
                          },
                        })}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>مريض المستخدم</label>
                      <TextField name="name" disabled variant="outlined" />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>وقت البدء</label>
                      <TextField
                        name="name"
                        value={moment(appointment?.startTime).fromNow()}
                        onChange={(e) => {
                          setAppointment({
                            ...appointment,
                            startTime: e.target.value,
                          });
                        }}
                        {...register("email", {
                          required: "email is required!",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>وقت النهاية</label>
                      <TextField
                        name="name"
                        value={moment(appointment?.endTime).fromNow()}
                        onChange={(e) => {
                          setAppointment({
                            ...appointment,
                            endTime: e.target.value,
                          });
                        }}
                        {...register("email", {
                          required: "email is required!",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.div}>
                      <label>وافق</label>
                      <TextField
                        disabled
                        name="name"
                        value={appointment?.isApproved}
                        onChange={(e) => {
                          setAppointment({
                            ...appointment,
                            email: e.target.value,
                          });
                        }}
                        {...register("email", {
                          required: "email is required!",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                        variant="outlined"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button type="submit" color="info">
                  تحديث
                </Button>
              </CardFooter>
              <ToastContainer />
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
