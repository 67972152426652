import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForm } from "react-hook-form";
import { createModerator } from "apis/auth";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { compactObj } from "../../utilities";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FieldErrors from "components/FieldErrors";
import Dialog from "@material-ui/core/Dialog";
import styles from "./styles";
import { useHistory } from "react-router";
const useStyles = makeStyles(styles);

export default function CreateModerator({
  modalOpen,
  setModalOpen,
  fetchData,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [moderator, setModerator] = useState({});
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const notify = (message) => toast(message);
  const handleCreate = async () => {
    try {
      let data = compactObj(moderator);
      let res = await createModerator(data);
      notify("تم إنشاء الوسيط بنجاح");
      setModalOpen(!modalOpen);
      window.location.reload(false);
      fetchData();
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
    }
  };
  function back() {
    history.push("/rtl/moderators");
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={4} sm={4} md={6}>
          <Dialog
            open={modalOpen}
            onClose={() => {
              setModalOpen(!modalOpen);
            }}
            className={classes.root}
            fullWidth={true}
            maxWidth={"xs"}
          >
            <Card className={classes.moderatorModelCard}>
              {loading ? null : (
                <form noValidate onSubmit={handleSubmit(handleCreate)}>
                  <CardHeader color="info">
                    <h4 className={classes.cardTitleWhite}>إنشاء ملف تعريف</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.div}>
                          <label>البريد الإلكتروني</label>
                          <TextField
                            defaultValue={moderator?.email}
                            name="email"
                            onChange={(e) => {
                              setModerator({
                                ...moderator,
                                email: e.target.value,
                              });
                            }}
                            inputRef={register({
                              required: "إن البريد الإلكترونى حقل مطلوب",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message:
                                  "لقد أدخلت عنوان بريد إلكتروني غير صالح",
                              },
                              minLength: {
                                value: 6,
                                message:
                                  "يجب أن يكون طول البريد الإلكتروني أكبر من ستة أحرف",
                              },
                            })}
                            variant="outlined"
                          />
                        </div>
                        <div className={classes.error}>
                          <FieldErrors error={errors?.email?.message} />
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.div}>
                          <label>كلمه السر</label>
                          <TextField
                            defaultValue={moderator?.password}
                            name="password"
                            onChange={(e) => {
                              setModerator({
                                ...moderator,
                                password: e.target.value,
                              });
                            }}
                            type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                            InputProps={{
                              // <-- This is where the toggle button is added.
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            inputRef={register({
                              required: "كلمه السر هي حقل مطلوب",
                              minLength: {
                                value: 8,
                                message: "يجب أن تتكون كلمة السر من 8 أحرف",
                              },
                            })}
                            variant="outlined"
                          />
                        </div>
                        <div className={classes.error}>
                          <FieldErrors error={errors?.password?.message} />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter className={classes.moderatorModelCardFooter}>
                    <Button type="submit" color="info">
                      تحديد
                    </Button>
                  </CardFooter>
                </form>
              )}
              <ToastContainer />
            </Card>
          </Dialog>
        </GridItem>
      </GridContainer>
    </div>
  );
}
