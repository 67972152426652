import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { setAuthHeader } from "../../apis/axios-configured";
import { login } from "../../apis/auth";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import FieldErrors from "components/FieldErrors";
import styles from "./styles";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const useStyles = makeStyles(styles);

export default function SignIn() {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const { history } = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const notify = (message) => toast(message);
  const handleLogin = async (payload) => {
    try {
      setErrorMsg("");
      let response = await login(payload);
      localStorage.setItem("token", response?.token);
      setAuthHeader(response?.token);
      let user = JSON.stringify(response?.user);
      localStorage.setItem("user", user);
      window.location.href = "rtl/rtl-page";
    } catch (error) {
      setErrorMsg(error.errMsg);
      notify(error.errMsg || "Something went wrong");
      console.error(errorMsg);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          تسجيل الدخول
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(handleLogin)}
        >
          <label> بريد إلكتروني </label>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            inputRef={register({
              required: "إن البريد الإلكترونى حقل مطلوب",
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              minLength: {
                value: 10,
                message: "لقد أدخلت عنوان بريد إلكتروني غير صالح",
              },
            })}
          />
          <div className={classes.error}>
            <FieldErrors error={errors?.email?.message} />
          </div>
          <label> كلمه السر </label>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            id="password"
            autoComplete="current-password"
            inputRef={register({
              required: "كلمه السر هي حقل مطلوب",
              minLength: {
                value: 8,
                message: "يجب أن تتكون كلمة المرور من 8 أحرف",
              },
            })}
          />
          <div className={classes.error}>
            <FieldErrors error={errors?.password?.message} />
          </div>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            تسجيل الدخول
          </Button>
          <ToastContainer />
        </form>
      </div>
    </Container>
  );
}
