import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import moment from "moment";
import TableLoading from "components/TableLoading";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();

  const { tableHead, tableData, tableHeaderColor, approveDoctor, loading } =
    props;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {loading ? (
            <TableLoading tableHead={tableHead} />
          ) : tableData.length > 0 ? (
            tableData.map((prop, key) => {
              return (
                <TableRow
                  key={key}
                  className={classes.tableBodyRow}
                  style={{
                    cursor: "pointer",
                    pointerEvents: "auto",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <TableCell className={classes.tableCell}>
                    {prop?.doctor?.fullName || "----"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {prop?.patient?.fullName || "----"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {prop?.commentOnDoctor || "----"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {prop?.doctorRating || "----"}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {moment(prop?.createdAt).fromNow()}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <span>لم يتم العثور على العناصر</span>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
