import axios from "./axios-configured";

export const blockUser = async (userId, block) => {
  return axios.put(`/admin/block/${userId}`, { block });
};
export const approveUser = async (userId, approvedVal) => {
  return axios.put(`/admin/approve/${userId}`, { approve: approvedVal });
};
export const deleteUser = async (userId, block) => {
  return axios.delete(`/admin/user/${userId}`);
};

export const onBoardUser = async (userId) => {
  return axios.put(`/admin/doctor/${userId}/onboard`);
};

export const fetchDoctors = async () => {
  return axios.get(`/admin/doctors`);
};
export const verifiedDoctors = async () => {
  return axios.get(`/admin/verifiedDoctors`);
};
export const registeredDoctors = async () => {
  return axios.get(`/admin/registeredDoctors`);
};
export const blockUsers = async () => {
  return axios.get(`/admin/blockDoctors`);
};

export const fetchWithdrawRequests = async () => {
  return axios.get(`/admin/withdraw-request`);
};

export const acceptWithdrawRequest = async (id) => {
  return axios.post(`/admin/withdraw-request/${id}`);
};

export const fetchAppointments = async () => {
  return axios.get(`/admin/appointments`);
};

export const fetchWallet = async () => {
  return axios.get(`/admin/transactions`);
};

export const getWithdrawRequestById = async (id) => {
  return axios.get(`/admin/withdraw-request/${id}`);
};

export const getAppointmentById = async (id) => {
  return axios.get(`/admin/appointments/${id}`);
};

export const getTransactionById = async (id) => {
  return axios.get(`/admin/transactions/${id}`);
};

export const getDoctorById = async (id) => {
  return axios.get(`/admin/doctors/${id}`);
};

export const getPatientById = async (id) => {
  return axios.get(`/admin/patients/${id}`);
};

export const editPatientProfile = async (id, payload) => {
  return axios.patch(`/admin/patients/${id}`, payload);
};

export const editDoctorProfile = async (id, payload) => {
  return axios.patch(`/admin/doctors/${id}`, payload);
};

export const editAppointment = async (id, payload) => {
  return axios.patch(`/admin/appointments/${id}`, payload);
};

export const createDoctor = async (payload) => {
  return axios.post(`/admin/create/doctor`, payload);
};

export const fetchReviews = async () => {
  return axios.get(`/admin/reviews`);
};

export const fetchAboutUs = async () => {
  return axios.get(`/auth/about-us`);
};

export const fetchTermsAndConditions = async () => {
  return axios.get("/auth/terms-and-conditions");
};

export const fetchUrlFromMediaId = async (id) => {
  return axios.get(`/media/${id}/url`);
};

export const fetchTransactions = async () => {
  return axios.get(`/admin/list/transactions`);
};
export const sendNotification = async (payload) => {
  return axios.post("/admin/notification/send", payload);
};

export const getTransactById = async (id) => {
  return axios.get(`/admin/transact/${id}`);
};
