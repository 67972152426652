import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Switch } from "@material-ui/core";
import moment from "moment";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { useHistory } from "react-router";
import MUIDataTable, { TableFilterList } from "mui-datatables";
import SendNotificationModal from "components/SendNotificationModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { compactObj } from "../../utilities";
import { sendNotification } from "apis/doctors";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles(styles);
export default function CustomTable(props) {
  const [visible, setVisible] = React.useState(false);
  const selectedRows = React.useRef([]);
  const notify = (message) => toast(message);
  const [formInput, setFormInput] = React.useState({});

  const handleCreate = async (formInput) => {
    try {
      let payload = compactObj({
        ...formInput,
        ...{ userIds: selectedRows.current },
      });
      let res = await sendNotification({
        ...payload,
        ...{ userIds: selectedRows.current },
      });
      notify("تم إرسال الإخطار بنجاح");
      setVisible(!visible);
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
    }
  };
  const history = useHistory();
  const classes = useStyles();

  const { tableData, approvePatient, blockPatient } = props;

  const showPatient = (id) => {
    history.push(`/rtl/patient/profile/${id}`);
  };

  const setVisibleHandler = () => {
    setVisible(!visible);
  };
  const columns = [
    {
      name: "name",
      label: "اسم",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "البريد الإلكتروني",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phoneNumber",
      label: "رقم الهاتف",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "gender",
      label: "جنس",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "city",
      label: "مدينة",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "block",
      label: "رفض",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "approved",
      label: "وافق",
    },
    {
      name: "created At",
      label: "أنشئت في",
    },
    {
      name: "actions",
      label: "أجراءات",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const CustomChip = ({ label, onDelete }) => {
    return (
      <Chip
        variant="outlined"
        color="secondary"
        label={label}
        onDelete={onDelete}
      />
    );
  };

  const CustomFilterList = (props) => {
    return <TableFilterList {...props} ItemComponent={CustomChip} />;
  };

  const data = tableData.map((patient) => {
    return [
      patient.fullName || "----",
      patient.email || "----",
      patient.phoneNumber || "----",
      patient.gender || "----",
      patient.city || "----",
      <Switch
        checked={patient.isBlocked}
        onChange={() => {
          blockPatient(patient);
        }}
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />,
      <Switch
        checked={patient?.isApproved === "0" ? false : true}
        onChange={() => {
          approvePatient(patient);
        }}
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />,
      moment(patient.createdAt).fromNow(),
      <VisibilityIcon
        className={classes.onMouseHover}
        onClick={() => showPatient(patient?.id)}
      >
        منظر
      </VisibilityIcon>,
    ];
  });

  let selectedIds = [];
  const options = {
    caseSensitive: "false",
    filterType: "checkbox",
    selectableRowsHeader: true,
    selectableRows: "multiple",
    filter: true,
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      selectedIds = [];
      allRowsSelected.forEach((element) => {
        let object = tableData[element.index];
        selectedIds.push(object.id);
      });
      selectedRows.current = selectedIds;
    },
    sort: true,
    sortOrder: {
      name: "created at",
      direction: "asc",
    },
    caseSensitive: false,
    downloadOptions: {
      filename: "patients.csv",
    },
  };
  return (
    <>
      <div className={classes.tableResponsive}>
        <Button color="info" onClick={setVisibleHandler}>
          إرسال إخطار
        </Button>
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
          components={{
            TableFilterList: CustomFilterList,
          }}
        />
      </div>
      <SendNotificationModal
        modalOpen={visible}
        setModalOpen={setVisibleHandler}
        selectedIds={selectedRows.current}
        notify={notify}
        formInput={formInput}
        setFormInput={setFormInput}
        handleCreate={handleCreate}
      />
    </>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
