import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";
import "assets/css/material-dashboard-react.css?v=1.10.0";
import SignIn from "views/SignIn/SignIn";
import WithdrawlRequestDetails from "views/DetailPages/WithdrawlRequestDetails.js";

const token = window.localStorage.getItem("token");
console.log(token);
ReactDOM.render(
  <BrowserRouter>
    {token ? (
      <Switch>
        <Route exact path="/rtl/doctors" component={RTL} />
        <Route path="/login" component={SignIn} />
        <Route path="/admin" component={Admin} />
        <Route path="/rtl" component={RTL} />
        <Route
          path="/withdrawl-request/details"
          component={WithdrawlRequestDetails}
        />
        <Redirect from="/" to="/rtl/doctors" />
      </Switch>
    ) : (
      <Switch>
        <Route path="/login" component={SignIn} />
        <Redirect from="*" to="/login" />
      </Switch>
    )}
  </BrowserRouter>,
  document.getElementById("root")
);
