import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import moment from "moment";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { useHistory } from "react-router";

import MUIDataTable, { TableFilterList } from "mui-datatables";
import Chip from "@material-ui/core/Chip";
import SendNotificationModal from "components/SendNotificationModal";
import "react-toastify/dist/ReactToastify.css";
import { compactObj } from "../../utilities";
import { sendNotification } from "apis/doctors";
import { ToastContainer, toast } from "react-toastify";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import "moment/locale/ar-ma";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const [visible, setVisible] = React.useState(false);
  const selectedRows = React.useRef([]);
  const [formInput, setFormInput] = React.useState({});

  const handleCreate = async (formInput) => {
    try {
      let payload = compactObj({
        ...formInput,
        ...{ userIds: selectedRows.current },
      });
      let res = await sendNotification({
        ...payload,
        ...{ userIds: selectedRows.current },
      });
      notify("تم إرسال الإخطار بنجاح");
      setVisible(!visible);
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
    }
  };
  const history = useHistory();
  const classes = useStyles();
  const setVisibleHandler = () => {
    setVisible(!visible);
  };
  const notify = (message) => toast(message);
  const { tableData, approveDoctor, blockDoctor, updateIsFeatured } = props;

  const columns = [
    {
      name: "name",
      label: "اسم",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "البريد الإلكتروني",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phoneNumber",
      label: "رقم الهاتف",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "gender",
      label: "جنس",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "city",
      label: "مدينة",
      options: {
        filter: true,
        sort: false,
        empty: false,
      },
    },
    {
      name: "block",
      label: "رفض",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "approve",
      label: "يوافق",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "featured",
      label: "متميز",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "created at",
      label: "أنشئت في",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "actions",
      label: "أجراءات",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  const CustomChip = ({ label, onDelete }) => {
    return (
      <Chip
        variant="outlined"
        color="secondary"
        label={label}
        onDelete={onDelete}
      />
    );
  };

  const CustomFilterList = (props) => {
    return <TableFilterList {...props} ItemComponent={CustomChip} />;
  };

  const showDoctor = (id) => {
    history.push(`/rtl/doctor/profile/${id}`);
  };
  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScroll: {
            maxHeight: "none",
          },
        },
      },
    });
  moment.locale("ara");
  const data = tableData.map((doctor) => {
    return [
      doctor.fullName || "----",
      doctor.email || "----",
      doctor.phoneNumber || "----",
      doctor.gender || "----",
      doctor.city || "----",
      <Switch
        checked={doctor.isBlocked}
        onChange={() => {
          blockDoctor(doctor);
        }}
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />,
      <Switch
        checked={doctor?.isApproved === "0" ? false : true}
        onChange={() => {
          approveDoctor(doctor);
        }}
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />,
      <Switch
        checked={doctor?.UserDoctor?.isFeatured === false ? false : true}
        onChange={() => {
          updateIsFeatured(doctor);
        }}
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />,
      moment(doctor.createdAt).fromNow(),
      <VisibilityIcon
        className={classes.onMouseHover}
        onClick={() => showDoctor(doctor?.id)}
      >
        منظر
      </VisibilityIcon>,
    ];
  });
  let selectedIds = [];
  const options = {
    responsive: "scroll",
    caseSensitive: "false",
    filterType: "checkbox",
    selectableRowsHeader: true,
    selectableRows: "multiple",
    filter: true,
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      selectedIds = [];
      allRowsSelected.forEach((element) => {
        let object = tableData[element.index];
        selectedIds.push(object.id);
      });
      selectedRows.current = selectedIds;
    },
    caseSensitive: false,
    downloadOptions: {
      filename: "doctors.csv",
    },
  };

  return (
    <>
      <Button
        type="submit"
        color="info"
        onClick={() => {
          history.push("/rtl/doctor/create");
        }}
      >
        اضف جديد
      </Button>
      <Button onClick={setVisibleHandler} color="info">
        إرسال إخطار
      </Button>
      <div>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
            components={{
              TableFilterList: CustomFilterList,
            }}
          />
        </MuiThemeProvider>
      </div>
      <SendNotificationModal
        modalOpen={visible}
        setModalOpen={setVisibleHandler}
        selectedIds={selectedRows.current}
        notify={notify}
        formInput={formInput}
        setFormInput={setFormInput}
        handleCreate={handleCreate}
      />
    </>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
