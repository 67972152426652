import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForm } from "react-hook-form";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FieldErrors from "components/FieldErrors";
import Dialog from "@material-ui/core/Dialog";
import styles from "./styles";
import { changePassword } from "apis/admins";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles(styles);

export default function CreateModerator({
  passwordModalOpen,
  setPasswordModalOpen,
  activeId,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const ITEM_HEIGHT = 28;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const notify = (message) => toast(message);
  const handleUpdate = async (data) => {
    try {
      let res = await changePassword(activeId, data);
      notify("تم تحديث كلمة السر بنجاح");
      setPasswordModalOpen(false);
    } catch (e) {
      notify(e.errMsg);
      console.log(e);
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={4} sm={4} md={6}>
          <Dialog
            open={passwordModalOpen}
            onClose={() => {
              setPasswordModalOpen(!passwordModalOpen);
            }}
            className={classes.root}
            fullWidth={true}
            maxWidth={"xs"}
          >
            <Card className={classes.moderatorModelCard}>
              <CardHeader color="info">
                <h4 className={classes.cardTitleWhite}>تغيير كلمة المرور</h4>
              </CardHeader>
              {loading ? null : (
                <form noValidate onSubmit={handleSubmit(handleUpdate)}>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.div}>
                          <label>كلمه السر</label>
                          <TextField
                            name="newPassword"
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            inputRef={register({
                              required: "كلمه السر هي حقل مطلوب",
                              minLength: {
                                value: 8,
                                message: "يجب أن تتكون كلمة السر من 8 أحرف",
                              },
                            })}
                            variant="outlined"
                          />
                        </div>
                        <div className={classes.error}>
                          <FieldErrors error={errors?.newPassword?.message} />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter className={classes.moderatorModelCardFooter}>
                    <Button type="submit" color="info">
                      تحديث
                    </Button>
                  </CardFooter>
                </form>
              )}
              <ToastContainer />
            </Card>
          </Dialog>
        </GridItem>
      </GridContainer>
    </div>
  );
}
