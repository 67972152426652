import React, { useState, useEffect } from "react";
import List from "./List";
import { fetchQueries, markQueryAsCompleted } from "../../../apis/admins";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ListItem } from "@material-ui/core";
function Queries() {
  const [loading, setLoading] = useState(false);
  const [queries, setQueries] = useState([]);
  const notify = (message) => toast(message);

  async function fetchData() {
    try {
      setLoading(true);
      let queries = await fetchQueries();
      console.log(queries);
      setQueries([...queries]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify("Error loading queries");
    }
  }
  const completeQuery = async (request) => {
    try {
      await markQueryAsCompleted(request.id);
      notify("تم وضع علامة على الاستعلام على أنه مكتمل");
      fetchData();
    } catch (error) {
      setRequests([...requests]);
      notify("خطأ أثناء استكمال الاستعلام!");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <List
        queries={queries}
        setQueries={setQueries}
        completeQuery={completeQuery}
        loading={loading}
      />
      <ToastContainer />
    </div>
  );
}

export default Queries;
