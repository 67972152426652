import axios from "./axios-configured";

export const login = (payload) => {
  return axios.post("/admin/auth/signIn", payload);
};

export const createModerator = async (payload) => {
  return axios.post("/admin/create/moderator", payload);
};

export const createFaqs = async (payload) => {
  return axios.post("/admin/create/faqs", payload);
};

export const createAboutUs = async (payload) => {
  return axios.post("/admin/create/about-us", payload);
};

export const createBanner = async (payload) => {
  return axios.post("/admin/create/banner", payload);
};

export const createTermsAndCondition = async (payload) => {
  return axios.post("/admin/create/terms-and-condition", payload);
};

export const getListOfBanners = async (id) => {
  return axios.get("/admin/banners");
};

export const editBanner = async (id, payload) => {
  return axios.put(`/admin/poster/${id}`, payload); //using poster instead of banner because of adblocker
};

export const getBanner = async (id) => {
  return axios.get(`/admin/poster/${id}`); //using poster instead of banner because of adblocker
};
